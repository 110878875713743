import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 98;
  background: #f5f5f5;

  .map-marker {
    margin-top: -30px;
  }

  .driver-info {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 30px;
    z-index: 99;

    .driver-info-content {
      width: 100%;
      height: 90px;
      background: #fafafa;
      padding: 20px;

      border: 1px solid #e1e1e1;
      display: flex;
      justify-content: space-between;

      strong {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;

        color: #181818;
        margin-bottom: 15px;
        display: flex;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        * {
          text-align: right;
        }
      }

      span {
        display: flex;
        align-items: center;
        .icon {
          margin: 0 10px;
          color: #808080;
          font-size: 7px;
        }
        small {
          font-family: 'Chillax';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          /* or 14px */

          /* text-align: right; */

          color: #6a6a6a;
        }
      }
    }
  }

  .address {
    box-sizing: border-box;
    width: 240px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    margin-top: 12px;
  }

  .addressField {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
`;
