import { ToastContainer } from "react-toastify";
import { AppRoutes } from "./routes";

import "react-toastify/dist/ReactToastify.css";

import { LoadScript } from "@react-google-maps/api";

import "./assets/Satoshi-Regular.otf";
import "./assets/Satoshi-Medium.otf";
import "./assets/Satoshi-Bold.otf";
import "./assets/Satoshi-Black.otf";

import "./assets/Chillax-Regular.otf";
import "./assets/Chillax-Medium.otf";
import "./assets/Chillax-Semibold.otf";
import "./assets/Chillax-Bold.otf";

import "./styles.css";

import GlobalStyles from "./styles/global";

const librariesScript: any = ["places"];

function App() {
  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyBxYfbK8X8-0GdDQCbQSpvLnKvtcjzkFoA"}
      libraries={librariesScript}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        style={{ zIndex: 9999 }}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <AppRoutes />
      <GlobalStyles />
    </LoadScript>
  );
}

export default App;
