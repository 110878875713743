import styled, { css } from 'styled-components';

import burgerImg from '../../assets/burger.png';

export const Container = styled.div`
  grid-area: content;

  /* padding: 50px; */
  background: #f5f5f5;
  display: flex;
  padding: 50px 50px 50px 0;
  flex-direction: column;

  h1 {
    color: #000;
    font-size: 11px;
  }

  .table {
    height: 100%;
    margin-top: 50px;

    .MuiDataGrid-row {
      cursor: pointer;
    }

    * {
      outline: none !important;
    }
  }

  .top-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 0 30px;
    }
    a {
      text-decoration: none;
    }
    h2 {
      /* margin-left: 15px; */
    }
  }

  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
  h3 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
`;
