import React, { useEffect, useState } from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Container } from './styles';
import MenuWrapper from '../../components/MenuWrapper';
import { WithMenuContainer } from '../../styles/global';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

const columns = [
  {
    field: 'title',
    headerName: 'Título',
    width: 200,
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 200,
  },
  {
    field: 'price',
    headerName: 'Preço atual',
    width: 150,
    type: 'number',
    valueGetter: (params: any) =>
      `${params.row.price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'old_price',
    headerName: 'Preço antigo',
    type: 'number',
    width: 110,
    valueGetter: (params: any) =>
      `${params.row.old_price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'discount',
    headerName: 'Desconto',
    type: 'number',
    width: 110,
    valueGetter: (params: any) =>
      `${params.row.discount.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'sells',
    headerName: 'Vendas',
    type: 'number',
    width: 110,
  },
  {
    field: 'likes',
    headerName: 'Curtidas',
    type: 'number',
    width: 110,
  },
  {
    field: 'renue_montly',
    headerName: 'Receita de abril',
    type: 'number',
    width: 180,
    valueGetter: (params: any) =>
      `${params.row.renue_montly.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'renue_total',
    headerName: 'Receita total',
    type: 'number',
    width: 180,
    valueGetter: (params: any) =>
      `${params.row.renue_total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'updated_at',
    headerName: 'Última alteração',
    type: 'number',
    width: 200,
    valueGetter: (params: any) =>
      `${new Date(params.row.updated_at).toLocaleString()}`,
  },
  {
    field: 'created_at',
    headerName: 'Data de criação',
    type: 'number',
    width: 200,
    valueGetter: (params: any) =>
      `${new Date(params.row.created_at).toLocaleString()}`,
  },
];

// const rows = [
//   {
//     id: 1,
//     title: 'Lata de coca-cola',
//     description: 'Lata de 350ml',
//     price: 3,
//     old_price: 2.5,
//     discount: 3 - 2.5,
//     sells: 100,
//     renue_montly: 30,
//     renue_total: 300,
//     updated_at: Date.now(),
//     created_at: Date.now(),
//   },
//   {
//     id: 2,
//     title: 'Lata de coca-cola',
//     description: 'Lata de 350ml',
//     price: 3,
//     old_price: 2.5,
//     discount: 3 - 2.5,
//     sells: 100,
//     renue_montly: 30,
//     renue_total: 300,
//     updated_at: Date.now(),
//     created_at: Date.now(),
//   },
//   {
//     id: 3,
//     title: 'Lata de coca-cola',
//     description: 'Lata de 350ml',
//     price: 3,
//     old_price: 2.5,
//     discount: 3 - 2.5,
//     sells: 100,
//     renue_montly: 30,
//     renue_total: 300,
//     updated_at: Date.now(),
//     created_at: Date.now(),
//   },
// ];

const Products = () => {
  const [productsData, setProductsData] = useState([]);

  const [rows, setRows] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function load() {
      const { data } = await api.get(
        `/places/6265a43d21ca6e602ea5a41e/products`
      );

      setProductsData(data);

      const newRows = data.map((product: any) => ({
        id: product._id,
        title: product.title,
        description: product.description,
        price: product.price,
        old_price: product.old_price,
        discount: product.old_price - product.price,
        sells: 0,
        likes: product.likes.length,
        renue_montly: 0,
        renue_total: 0,
        updated_at: product.updatedAt,
        created_at: product.createdAt,
      }));

      setRows(newRows);
    }

    load();
  }, []);

  return (
    <WithMenuContainer>
      <MenuWrapper />
      <Container>
        <div className="top-head">
          <h2>Produtos</h2>
          <Link to="/products/new">
            <button className="cta-main">Adicionar</button>
          </Link>
        </div>
        <div className="table">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            style={{ fontFamily: 'Chillax' }}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            onRowClick={(e) => {
              history.push(`/products/${e.id}`);
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
            editMode="row"
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
          />
        </div>
      </Container>
    </WithMenuContainer>
  );
};

export default Products;
