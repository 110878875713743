import styled, { css } from 'styled-components';

import burgerImg from '../../assets/burger.png';

export const Container = styled.div`
  grid-area: content;

  /* padding: 50px; */
  background: #f5f5f5;
  display: grid;
  grid-template-columns: 0.7fr 0.8fr 0.6fr;
  grid-template-areas: 'list order client';
  grid-template-rows: auto;

  h1 {
    color: #000;
    font-size: 11px;
  }

  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
  h3 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-area: list;
    padding-top: 50px;
    z-index: 99;
    .top-with-button {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .add-button {
        width: 42px;
        height: 42px;
        background: #ff0018;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        margin-right: 50px;
        cursor: pointer;
      }
    }

    .search-order-input {
      min-height: 58px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 0 20px;
      color: #181818;

      margin: 30px 0;
    }
    .search-order-input::placeholder {
      color: #969696;
    }

    .tags {
      display: block;
      .tag {
        margin-bottom: 10px;
        margin-right: 10px;
        /* min-width: 82px; */
        height: 30px;
        padding: 0 10px;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
        color: #181818;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;

        color: #181818;
      }

      .tag.active {
        background: #ff0018;
        color: #fff;
        border-color: #ff0018;
      }
    }

    .scroll-wrapper-out {
      position: relative;
      height: 100%;
      margin-top: 20px;

      .scroll-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;

        .rcs-inner-handle {
          background: #d2d2d2;
        }

        .rcs-custom-scroll {
          border: 1px solid #d2d2d2;
          border-bottom-width: 0px;
        }

        .rcs-inner-container {
          background: #f3f3f3;
          padding: 30px;
        }
      }
    }
  }

  .content {
    grid-area: order;
    background: #fafafa;
    position: relative;
    height: 100%;

    .order-grid {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      .rcs-inner-container {
        padding: 50px;
      }
      .rcs-inner-handle {
        background: #d2d2d2;
      }
    }

    .title-status {
      margin-left: 10px;
      background: #ffffff;
      border: 1px solid #ff0018;
      box-sizing: border-box;
      box-shadow: -3px 4px 45px 14px rgba(0, 0, 0, 0.06);
      border-radius: 9.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 10px;
      font-family: 'Chillax';
      width: 100%;
      min-width: 87px;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;
      text-align: center;
      padding: 0 10px;
      text-transform: uppercase;
      color: #ff0018;
    }

    .top-with-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        display: flex;
        align-items: center;
      }

      .btns {
        display: flex;
      }

      .print-button {
        margin-left: 10px;
      }

      .edit-button,
      .print-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 21px;

        cursor: pointer;

        color: #7c7c7c;
        font-size: 15px;

        background: #e0e0e0;
      }
    }

    .cart {
      margin-top: 50px;

      .cart-itens {
        margin-top: 30px;
      }
    }

    .line + .line {
      margin-top: 12px;
    }

    .line.line-red {
      span,
      strong {
        color: #ff0018;
      }
    }

    .divisor {
      width: 100%;
      height: 1px;
      background: #c4c4c4;
    }
    .line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      /* margin-top: 30px; */

      span {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;

        color: #181818;
      }

      small {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        /* or 14px */

        text-align: right;

        color: #6a6a6a;
      }

      strong {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;
        /* or 17px */

        text-align: right;

        color: #181818;
      }

      strong.less {
        color: #808080;
      }
    }

    .client-notes {
      margin: 15px 0 25px 0;
      .MuiPaper-root {
        background: none;
      }
      .MuiAccordionDetails-root {
        padding: 0px;
        padding-left: 35px;
        p {
          font-family: 'Satoshi';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 120%;
          /* or 17px */

          color: #808080;
        }
      }

      .MuiAccordionSummary-root {
        flex-flow: row-reverse;
        padding: 0;
        margin: 0;
        .MuiTypography-root {
          font-family: 'Satoshi';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 100%;

          color: #181818;
        }

        .MuiAccordionSummary-expandIconWrapper {
          width: 20px;
          border-radius: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e0e0e0;
          color: #7c7c7c;
          margin-right: 15px;
        }
      }
      .MuiPaper-root::before {
        display: none !important;
      }
    }
  }

  .client {
    grid-area: client;
    position: relative;
    /* padding: 50px; */

    .client-top-head {
      height: 42px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    .line + .line {
      margin-top: 30px;
    }

    .line {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      small {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #808080;
      }

      span {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
        margin-top: 10px;
        color: #181818;
      }
    }
  }
`;

interface OrderItemContainerProps {
  selected?: boolean;
}

export const OrderItemContainer = styled.div<OrderItemContainerProps>`
  background-color: #ffffff;
  box-shadow: -3px 4px 45px 14px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  min-height: 83px;
  width: 100%;
  margin-bottom: 20px;

  padding: 20px;
  cursor: pointer;

  transition: background-color 0.3s;

  ${(props) =>
    props.selected &&
    css`
      background-color: #ff0018;

      transition: background-color 0.3s;

      strong,
      span,
      small,
      .icon {
        color: #fff !important;
      }

      .order-right {
        .line {
          .status {
            border-color #fff;
          }
        }
      }
    `}

  strong {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;

    color: #181818;
  }

  display: flex;
  justify-content: space-between;

  align-items: flex-start;

  .order-left {
    display: flex;
    flex-direction: column;
    span {
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      color: #969696;
      margin-top: 5px;
    }
  }

  .order-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* width: 100%; */

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .icon {
        margin: 0 10px;
      }

      small {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        width: 100%;
        /* or 13px */
        text-align: right;
        /* white-space: pre; */

        color: #969696;
      }
      small.red {
        color: #ff0018;
      }

      .status {
        margin-left: 10px;
        background: #ffffff;
        border: 1px solid #ff0018;
        box-sizing: border-box;
        box-shadow: -3px 4px 45px 14px rgba(0, 0, 0, 0.06);
        border-radius: 9.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        border-radius: 10px;
        font-family: 'Chillax';
        width: 100%;
        min-width: 87px;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 100%;
        text-align: center;
        padding: 0 10px;
        text-transform: uppercase;
        color: #ff0018;
      }
    }
  }
`;

export const CartItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & + & {
    margin-top: 20px;
  }
  p {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #808080;
    padding-left: 65px;
  }
`;

interface CartItemContainerProps {
  itemImage: string;
}

export const CartItemContainer = styled.div<CartItemContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  .left {
    display: flex;
    align-items: center;
  }

  .photo {
    height: 48px;
    width: 48px;
    background: url(${(props) => props.itemImage}) no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 0px 2px #181818;
    border-radius: 6px;
  }

  span {
    margin: 0 15px;

    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;

    color: #181818;
  }

  strong {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    /* or 17px */

    text-align: left;

    color: #181818;
  }

  strong.less {
    color: #808080;
  }
`;

export const FloatingButtonContainer = styled.div`
  bottom: 50px;
  right: 50px;

  position: absolute;

  .button {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ff0018;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 25px;
    cursor: pointer;
    line-height: 100%;
    animation-name: floatingAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-i;
  }

  @keyframes floatingAnim {
    0% {
      box-shadow: none;
    }
    50% {
      box-shadow: 0px 0px 17px 0px #ff001894;
    }
    100% {
      box-shadow: none;
    }
  }
`;

export const ModalVariantsContainer = styled.div`
  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
    margin-bottom: 20px;
  }

  .input-select-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 20px;
    label {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 100%;

      color: #181818;
      margin-bottom: 20px;
    }

    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #808080;
      margin-top: 15px;
    }

    .input-select-wrap {
      width: 100%;

      .input-select__control {
        height: 58px;
        padding: 5px 20px !important;
      }

      .input-select__value-container {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #181818;
      }

      .input-select__option {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
      }
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    label {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 100%;

      color: #181818;
      margin-bottom: 20px;
    }

    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #808080;
      margin-top: 15px;
    }

    input {
      min-height: 58px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 0 20px;
      color: #181818;
    }
    input::placeholder {
      color: #969696;
    }

    textarea {
      min-height: 100px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      resize: vertical;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 20px;
      color: #181818;
    }

    textarea::placeholder {
      color: #969696;
    }
  }
`;

export const DeliveryMenContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #ddd;
  /* box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 25%); */
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  & + & {
    margin-top: 20px;
  }

  strong {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;

    color: #181818;
    margin-bottom: 10px;
  }

  span {
    display: flex;
    align-items: center;
    .icon {
      margin: 0 10px;
      color: #808080;
      font-size: 7px;
    }
    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      color: #808080;
    }
  }
`;
