import React, { useRef, useState, useCallback } from "react";

import {
  CartItemContainer,
  CartItemWrapper,
  Container,
  FloatingButtonContainer,
  OrderItemContainer,
  ModalVariantsContainer,
  DeliveryMenContainer,
} from "./styles";

import CustomScroll from "react-custom-scroll";

import MenuWrapper from "../../components/MenuWrapper";

import { ptBR } from "date-fns/locale";

import MapComponent from "./components/MapComponent";

import { WithMenuContainer } from "../../styles/global";
import {
  FaAngleDown,
  FaCircle,
  FaMotorcycle,
  FaPencilAlt,
  FaPlus,
  FaPrint,
} from "react-icons/fa";

import { GiCookingGlove } from "react-icons/gi";

import { Form } from "@unform/web";

import Tooltip from "@mui/material/Tooltip";

import {
  differenceInDays,
  differenceInMinutes,
  formatDistance,
} from "date-fns";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import api from "../../services/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { components } from "react-select";
import SelectInput from "../../components/SelectInput";
import { FormHandles, FormProps } from "@unform/core";
import { toast } from "react-toastify";

const Orders = () => {
  const [selectedType, setSelectedType] = useState<any>("all");
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const [currentOrder, setCurrentOrder] = useState<any>(null);

  const [currentOrderStatus, setCurrentOrderStatus] = useState<any>(null);

  const [searchInput, setSearchInput] = useState<any>(null);

  const changeOrderStatusForm = useRef<FormHandles>(null);

  const [orders, setOrders] = useState<any>([]);

  const [deliveryModalOpen, setDeliveryModalOpen] = React.useState(false);
  const [statusModalOpen, setStatusModalOpen] = React.useState(false);

  const [orderStatusOptions, setOrderStatusOptions] = useState([
    {
      label: "Em espera",
      value: "waiting",
    },
    {
      label: "Em preparo",
      value: "started",
    },
    {
      label: "Em trajeto",
      value: "road",
    },
    {
      label: "Concluído",
      value: "finished",
    },
  ]);

  const [orderStatusSelectValue, setOrderStatusSelectValue] =
    useState<any>(null);

  const filterOrderStatusOptions = (inputValue: string) => {
    return orderStatusOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOrderStatusOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(() => {
      callback(filterOrderStatusOptions(inputValue));
    }, 1000);
  };

  const handleSetOrderAsStarted = async () => {
    if (currentOrder && currentOrder.status !== "started") {
      const { data } = await api.put(
        `/places/${currentOrder.place_id}/orders/${currentOrder._id}`,
        {
          status: "started",
        }
      );

      setCurrentOrderStatus("started");

      var oldOrdersList = [...orders];

      oldOrdersList = oldOrdersList.map((order) => {
        if (order._id === currentOrder._id) {
          return { ...order, status: data.status };
        } else {
          return order;
        }
      });

      setCurrentOrder((old: any) => ({ ...old, status: data.status }));

      toast.success("Pedido em preparo!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setOrders(oldOrdersList);
    }
  };

  const handleOpenDeliveryModal = () => {
    setDeliveryModalOpen(true);
  };

  const handleCloseDeliveryModal = () => {
    setDeliveryModalOpen(false);
  };

  const handleOpenStatusModal = () => {
    setStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  interface FloatingButtonProps {
    handle?: any;
    placeholder: string;
    icon: any;
  }

  const FloatingButton: React.FC<FloatingButtonProps> = ({
    handle,
    placeholder,
    icon,
  }) => {
    return (
      <FloatingButtonContainer onClick={handle}>
        <Tooltip className="tooltip" placement="left" title={placeholder}>
          <div className="button">{icon}</div>
        </Tooltip>
      </FloatingButtonContainer>
    );
  };

  useEffect(() => {
    async function load() {
      const interval = setTimeout(() => {
        if (searchInput && searchInput !== null && searchInput !== "") {
          setSelectedType(null);
          setSelectedType("all");
        }
      }, 2000);

      return () => clearTimeout(interval);
    }

    load();
  }, [searchInput]);

  interface OrderItemProps {
    selected?: boolean;
    handle?: any;
    data?: any;
  }

  useEffect(() => {
    async function load() {
      // const { data: authData } = await api.post('/owner-sessions', {
      //   email: 'thiago@oliverdelivery.com',
      //   password: '12345678',
      // });

      // console.log(authData);

      const { data } = await api.get(`/places/6265a43d21ca6e602ea5a41e/orders`);

      if (data) {
        console.log(data);
        setOrders(data);
        setSelectedOrder(data[0]._id);
        console.log(data[0].status);

        setCurrentOrderStatus(data[0].status);
        setOrderStatusSelectValue(
          [
            {
              label: "Em espera",
              value: "cart",
            },
            {
              label: "Em espera",
              value: "waiting",
            },
            {
              label: "Em preparo",
              value: "started",
            },
            {
              label: "Em trajeto",
              value: "road",
            },
            {
              label: "Concluído",
              value: "finished",
            },
          ].filter((i) => i.value === data[0].status)[0]
        );
      }
    }
    load();
  }, []);

  useEffect(() => {
    async function load() {
      var { data } = await api.get(`/places/6265a43d21ca6e602ea5a41e/orders`);
      console.log("type select", data);
      console.log(selectedType);

      if (data) {
        const getTypeFilterResults = () => {
          switch (selectedType) {
            case "waiting":
              return data.filter((order: any) =>
                order.status === "waiting" ||
                order.status === "cart" ||
                order.status === "payed"
                  ? true
                  : false
              );
            case "started":
              return data.filter((order: any) => order.status === "started");
            case "road":
              return data.filter((order: any) => order.status === "road");
            case "finished":
              return data.filter(
                (order: any) =>
                  order.status === "delivered" || order.status === "finished"
              );
            case "all":
              return data;
            default:
              return data;
          }
        };

        data = getTypeFilterResults();

        if (selectedType !== "all") {
          // alert('abc');
          setSearchInput("");
        }

        if (selectedType === "all" && searchInput !== "" && searchInput) {
          const toFilter = searchInput.toLowerCase();

          data = data.filter((order: any) => {
            const titles =
              `${order.beauty_id} ${order.user_id.name} ${order.user_id.cpf} ${order.user_id.phone}`.toLowerCase();

            if (titles.search(toFilter) !== -1) {
              return true;
            } else {
              return false;
            }
          });
        }

        console.log("data filter", data);
        setOrders(data);
        setSelectedOrder(data[0]._id);
        console.log(data[0].status);

        setCurrentOrderStatus(data[0].status);
        setOrderStatusSelectValue(
          [
            {
              label: "Em espera",
              value: "cart",
            },
            {
              label: "Em espera",
              value: "waiting",
            },
            {
              label: "Em preparo",
              value: "started",
            },
            {
              label: "Em trajeto",
              value: "road",
            },
            {
              label: "Concluído",
              value: "finished",
            },
          ].filter((i) => i.value === data[0].status)[0]
        );
      }
    }
    load();
  }, [selectedType]);

  const getStatus = (status: any) => {
    if (!status) {
      return "";
    }

    switch (status) {
      case "cart":
        return "EM ESPERA";
      case "waiting":
        return "EM ESPERA";
      case "payed":
        return "EM ESPERA";
      case "started":
        return "EM PREPARO";
      case "road":
        return "EM TRAJETO";
      case "delivered":
        return "CONCLUÍDO";
      case "finished":
        return "CONCLUÍDO";
    }
  };

  useEffect(() => {
    async function load() {
      if (orders && selectedOrder !== null) {
        const selected = orders.filter(
          (order: any) => order._id === selectedOrder
        )[0];

        var newCart = [];

        var productsId: any = [];

        selected.cart.map((product: any) => {
          if (productsId.indexOf(product.product_id) === -1) {
            productsId.push(product.product_id);
          }
        });

        const productsModels = await Promise.all(
          productsId.map(async (product_id: any) => {
            const { data: productModel } = await api.get(
              `/places/6265a43d21ca6e602ea5a41e/products/${product_id}`
            );

            return productModel;
          })
        );

        console.log(productsModels);

        newCart = selected.cart.map((product: any) => {
          return {
            ...product,
            productModel: productsModels.filter(
              (p: any) => p._id === product.product_id
            )[0],
          };
        });

        selected.cart = newCart;

        console.log(selected);

        setCurrentOrder(selected);

        setCurrentOrderStatus(selected.status);
        setOrderStatusSelectValue(
          [
            {
              label: "Em espera",
              value: "cart",
            },
            {
              label: "Em espera",
              value: "waiting",
            },
            {
              label: "Em preparo",
              value: "started",
            },
            {
              label: "Em trajeto",
              value: "road",
            },
            {
              label: "Concluído",
              value: "finished",
            },
          ].filter((i) => i.value === selected.status)[0]
        );
      }
    }
    load();
  }, [selectedOrder]);

  const OrderItem: React.FC<OrderItemProps> = ({ selected, handle, data }) => {
    const getTotalQtd = () => {
      var total = 0;

      if (!data?.cart) {
        return 0;
      }

      data.cart.map((product: any) => {
        total += product.qtd;
      });

      return total;
    };

    const getAgoTime = () => {
      if (data) {
        const origin = new Date(data?.createdAt);
        const now = new Date();

        if (differenceInDays(now, origin) > 0) {
          return origin.toLocaleDateString();
        }

        if (differenceInMinutes(now, origin) > 1) {
          return formatDistance(origin, now, {
            addSuffix: true,
            locale: ptBR,
          });
        } else {
          return "1m";
        }
      }
    };

    return (
      <OrderItemContainer onClick={() => handle()} selected={selected}>
        <div className="order-left">
          <strong>#{data?.beauty_id}</strong>
          <span>{data?.user_id.name}</span>
        </div>

        <div className="order-right">
          <div className="line">
            <small>{getTotalQtd()} un</small>
            <FaCircle className="icon" size={3} color="#969696" />
            <strong>
              {data?.total_value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </div>
          <div className="line">
            <small className="red">{getAgoTime()}</small>
            <div className="status">{getStatus(data?.status)}</div>
          </div>
        </div>
      </OrderItemContainer>
    );
  };

  const selectDeliveryMen = async () => {
    if (currentOrder && currentOrder.status === "started") {
      setCurrentOrderStatus("road");

      const { data } = await api.put(
        `/places/${currentOrder.place_id}/orders/${currentOrder._id}`,
        {
          status: "road",
        }
      );

      console.log(data);

      var oldOrdersList = [...orders];

      oldOrdersList = oldOrdersList.map((order) => {
        if (order._id === currentOrder._id) {
          return { ...order, status: data.status };
        } else {
          return order;
        }
      });

      setCurrentOrder((old: any) => ({ ...old, status: data.status }));

      setOrders(oldOrdersList);

      handleCloseDeliveryModal();
      toast.success("Processo de entrega iniciado!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,

        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const setOrderAsFinished = useCallback(async () => {
    if (
      (currentOrder && currentOrder.status === "road") ||
      (currentOrder && currentOrder.status === "delivered")
    ) {
      setCurrentOrderStatus("finished");

      const { data } = await api.put(
        `/places/${currentOrder.place_id}/orders/${currentOrder._id}`,
        {
          status: "finished",
        }
      );

      console.log(data);

      var oldOrdersList = [...orders];

      oldOrdersList = oldOrdersList.map((order) => {
        if (order._id === currentOrder._id) {
          return { ...order, status: data.status };
        } else {
          return order;
        }
      });

      setCurrentOrder((old: any) => ({ ...old, status: data.status }));

      setOrders(oldOrdersList);

      handleCloseDeliveryModal();
      toast.success("Pedido entregue e finalizado!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,

        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [
    currentOrder,
    setOrders,
    orders,
    handleCloseDeliveryModal,
    setCurrentOrderStatus,
  ]);

  const handleChangeOrderStatus = useCallback(async () => {
    if (orderStatusSelectValue && currentOrder) {
      setCurrentOrderStatus(orderStatusSelectValue.value);

      const { data } = await api.put(
        `/places/${currentOrder.place_id}/orders/${currentOrder._id}`,
        {
          status: orderStatusSelectValue.value,
        }
      );

      console.log(data);

      var oldOrdersList = [...orders];

      oldOrdersList = oldOrdersList.map((order) => {
        if (order._id === currentOrder._id) {
          return { ...order, status: data.status };
        } else {
          return order;
        }
      });

      setCurrentOrder((old: any) => ({ ...old, status: data.status }));

      setOrders(oldOrdersList);

      setStatusModalOpen(false);
    }
  }, [
    orderStatusSelectValue,
    currentOrder,
    orders,
    setOrders,
    setStatusModalOpen,
  ]);

  interface CartItemProps {
    data?: any;
  }

  const CartItem: React.FC<CartItemProps> = ({ data }) => {
    return (
      <CartItemWrapper>
        <CartItemContainer itemImage={data.productModel.images[0]}>
          <div className="left">
            <div className="photo"></div>
            <span>{data.qtd}x</span>
            <strong>{data.productModel.title}</strong>
          </div>
          <strong className="less">
            {" "}
            {data.productModel.price.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </strong>
        </CartItemContainer>
        <p>
          {data.options.map((option: any) => {
            if (option.selected.length > 1) {
              return (
                <>
                  ◾ {option.origin.name}:{" "}
                  {option.selected.map((vari: any, index: number) =>
                    index === 0 ? vari.name : ", " + vari.name
                  )}
                  <br />
                </>
              );
            } else {
              return (
                <>
                  ◾ {option.origin.name}:{" "}
                  {option.selected.map((vari: any, index: number) => vari.name)}
                  <br />
                </>
              );
            }
          })}
          {data.product_notes && (
            <>
              <br />
              Obs.: {data.product_notes}
            </>
          )}
        </p>
      </CartItemWrapper>
    );
  };

  const DeliveryMenItem = () => {
    return (
      <DeliveryMenContainer onClick={selectDeliveryMen}>
        <strong>João Paulo</strong>
        <span>
          <small>Disponível</small>
          <FaCircle className="icon" />
          <small>A 2km de distância</small>
          <FaCircle className="icon" />
          <small>Chegaria em aprox. 5m</small>
        </span>
      </DeliveryMenContainer>
    );
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#ff0018",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  const customStylesInputModal = {
    option: (provided: any, state: any) => ({
      ...provided,
      //
      // color: "#ffffff",
      // borderWidth: "0px",
      // boxShadow: "none",
      // fontFamily: "Oxygen",
      // fontWeight: "bold",
      //
      // fontSize: 12,
    }),
    container: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: "100%",
      zIndex: 9998,
      // marginBottom: 20,
    }),
    indicatorSeparator: (provided: any) => ({
      // none of react-select's styles are passed to <Control />

      display: "none",
    }),
    control: (provided: any) => ({
      // ...provided,
      // none of react-select's styles are passed to <Control />
      width: "100%",
      display: "flex",
      zIndex: 98,
      background: "#eeeeee",
      padding: "5px 15px",
      // marginBottom: 20,
    }),
    valueContainer: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      padding: 0,
      // background: "#9D9D9D",
    }),
    dropdownIndicator: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      position: "relative",
      paddingLeft: 20,
      // background: "#9D9D9D",
    }),
    menu: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      // paddingLeft: "10px",
      // background: "#9D9D9D",
    }),
    menuList: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      // ...provided,
      // paddingLeft: "10px",
      ...provided,
      // background: "#9D9D9D",
    }),
    singleValue: (provided: any, state: any) => {
      // const opacity = state.isDisabled ? 0.5 : 1;
      // const transition = "opacity 300ms";

      return {
        ...provided,
      };
    },
  };

  const handleAddOrderByProducts = async () => {
    const { data: productsResponse } = await api.get(
      `/places/6265a43d21ca6e602ea5a41e/products`
    );

    if (productsResponse) {
      var totalPrice = 0;

      const cart = productsResponse.map((product: any) => {
        var newOptions: any = [];

        var itemPrice = product.price;

        product.options.map((option: any) => {
          var perOptionPrice = 0;

          if (option.type === "multiple") {
            option.variants.map((vari: any) => {
              if (vari.price && vari.price > 0) {
                perOptionPrice += vari.price;
              }
            });
          } else {
            if (option.variants[0].price && option.variants[0].price > 0) {
              perOptionPrice += option.variants[0].price;
            }
          }

          newOptions.push({
            origin: option,
            selected:
              option.type === "multiple" ? option.variants : option.variants[0],
          });

          itemPrice += perOptionPrice;
        });

        totalPrice += itemPrice;

        return {
          product_id: product._id,
          product_notes: "Notas exemplos",
          qtd: 1,
          options: newOptions,
        };
      });

      const oldOrder = [...orders][0];

      const payload = {
        cart,
        subtotal: totalPrice,
        total_value: totalPrice,
        delivery_cost: 10,
        wallet_value_used: 10,
        beauty_id: "000" + (Number(oldOrder.beauty_id) + 1),
        user_id: "6265998b3f79998ac09edd49",
        client_notes: "Notas do pedido",
        discount: 0,
        status: "waiting",
        pay_method: "app",

        address: {
          latitude: -22.909565624634514,
          longitude: -43.18098171680231,
          address: "Av. República do Chile - Centro",
          number: 46,
          city: "Rio de Janeiro",
          state: "RJ",
          country: "Brasil",
        },
      };

      const { data: orderResponse } = await api.post(
        `/places/6265a43d21ca6e602ea5a41e/orders`,
        payload
      );

      if (orderResponse) {
        const { data } = await api.get(
          `/places/6265a43d21ca6e602ea5a41e/orders`
        );

        if (data) {
          console.log(data);
          setOrders(data);
          setSelectedOrder(data[0]._id);
          console.log(data[0].status);

          setCurrentOrderStatus(data[0].status);
          setOrderStatusSelectValue(
            [
              {
                label: "Em espera",
                value: "cart",
              },
              {
                label: "Em espera",
                value: "waiting",
              },
              {
                label: "Em preparo",
                value: "started",
              },
              {
                label: "Em trajeto",
                value: "road",
              },
              {
                label: "Concluído",
                value: "finished",
              },
            ].filter((i) => i.value === data[0].status)[0]
          );
        }

        console.log(payload);
      }
    }
  };

  return (
    <WithMenuContainer>
      <MenuWrapper />
      <ThemeProvider theme={theme}>
        <Dialog
          className="modal-variants"
          open={deliveryModalOpen}
          onClose={handleCloseDeliveryModal}
        >
          <DialogContent>
            <ModalVariantsContainer>
              <h2>Selecionar entregador</h2>

              <div className="delivery-list">
                <DeliveryMenItem />
              </div>
            </ModalVariantsContainer>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCloseDeliveryModal}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="modal-variants"
          open={statusModalOpen}
          onClose={handleCloseStatusModal}
        >
          <DialogContent>
            <ModalVariantsContainer style={{ minHeight: 270 }}>
              <Form onSubmit={() => {}}>
                <h2>Alterar status do pedido</h2>
                <div className="input-select-group">
                  <label htmlFor="status">* Status do pedido</label>
                  <SelectInput
                    name="status"
                    placeholder="Altere o status do seu pedido"
                    required
                    styles={customStylesInputModal}
                    className="input-select-wrap"
                    classNamePrefix="input-select"
                    value={orderStatusSelectValue}
                    onChange={(e: any) => setOrderStatusSelectValue(e)}
                    loadOptions={loadOrderStatusOptions}
                    components={{
                      DropdownIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <FaAngleDown />
                        </components.DropdownIndicator>
                      ),

                      LoadingIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <div
                            className="add-product-svg"
                            style={{
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* <Loader
                        type="TailSpin"
                        color="#8b8b8b"
                        width={15}
                        height={15}
                      /> */}
                          </div>
                        </components.DropdownIndicator>
                      ),
                    }}
                    defaultOptions={orderStatusOptions}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: "#2c2c2c",

                        primary75: "#181818",
                        primary50: "#ddd",
                        primary25: "#eee",
                      },
                    })}
                  />
                  <small>
                    Alterar o status de um pedido influencia nas métricas
                  </small>
                </div>
              </Form>
            </ModalVariantsContainer>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCloseStatusModal}>
              Cancelar
            </Button>
            <Button
              type="submit"
              color="secondary"
              onClick={handleChangeOrderStatus}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      <Container>
        <div className="list">
          <div className="top-with-button">
            <h2>Lista</h2>
            <div onClick={handleAddOrderByProducts} className="add-button">
              <FaPlus />
            </div>
          </div>

          <input
            className="search-order-input"
            placeholder="Busque por código, nome, cpf ou telefone"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />

          <div className="tags">
            <div
              onClick={() => setSelectedType("all")}
              className={`tag ${selectedType === "all" ? "active" : ""}`}
            >
              Todos
            </div>
            <div
              onClick={() => setSelectedType("waiting")}
              className={`tag ${selectedType === "waiting" ? "active" : ""}`}
            >
              Em espera
            </div>
            <div
              onClick={() => setSelectedType("started")}
              className={`tag ${selectedType === "started" ? "active" : ""}`}
            >
              Em preparo
            </div>
            <div
              onClick={() => setSelectedType("road")}
              className={`tag ${selectedType === "road" ? "active" : ""}`}
            >
              Em trajeto
            </div>
            <div
              onClick={() => setSelectedType("finished")}
              className={`tag ${selectedType === "finished" ? "active" : ""}`}
            >
              Concluídos
            </div>
          </div>

          <div className="scroll-wrapper-out">
            <div className="scroll-wrapper">
              <CustomScroll flex="1">
                {orders.map((order: any) => (
                  <OrderItem
                    handle={() => setSelectedOrder(order._id)}
                    selected={selectedOrder === order._id}
                    data={order}
                  />
                ))}
                {/* <OrderItem
                  handle={() => setSelectedOrder(1)}
                  selected={selectedOrder === 1}
                />
                <OrderItem
                  handle={() => setSelectedOrder(2)}
                  selected={selectedOrder === 2}
                />
                <OrderItem
                  handle={() => setSelectedOrder(3)}
                  selected={selectedOrder === 3}
                />
                <OrderItem
                  handle={() => setSelectedOrder(4)}
                  selected={selectedOrder === 4}
                />
                <OrderItem
                  handle={() => setSelectedOrder(5)}
                  selected={selectedOrder === 5}
                />
                <OrderItem
                  handle={() => setSelectedOrder(6)}
                  selected={selectedOrder === 6}
                /> */}

                <div
                  style={{ width: "100%", height: "20px", marginTop: -10 }}
                ></div>
              </CustomScroll>
            </div>
          </div>
        </div>

        <div className="content">
          <CustomScroll className="order-grid" flex="1">
            {currentOrder ? (
              <>
                {currentOrder.status === "cart" ||
                currentOrder.status === "waiting" ? (
                  <FloatingButton
                    icon={<GiCookingGlove />}
                    placeholder="Marcar como 'Em preparo'"
                    handle={handleSetOrderAsStarted}
                  />
                ) : (
                  <></>
                )}
                {currentOrder.status === "started" && (
                  <FloatingButton
                    icon={<FaMotorcycle />}
                    placeholder="Iniciar delivery"
                    handle={handleOpenDeliveryModal}
                  />
                )}
                <div className="top-with-actions">
                  <h2>
                    Pedido
                    <div className="title-status">
                      {getStatus(currentOrder.status)}
                    </div>
                  </h2>
                  <div className="btns">
                    <div
                      onClick={handleOpenStatusModal}
                      className="edit-button"
                    >
                      <FaPencilAlt />
                    </div>
                    <div className="print-button">
                      <FaPrint />
                    </div>
                  </div>
                </div>

                <div className="line">
                  <span>Nome: {currentOrder.user_id.name}</span>
                  <small>
                    <>
                      {new Date(
                        `${currentOrder.createdAt}`
                      ).toLocaleDateString()}{" "}
                      ás <br />
                      {new Date(
                        `${currentOrder.createdAt}`
                      ).toLocaleTimeString()}
                    </>
                  </small>
                </div>
                {currentOrder.status === "finished" && (
                  <div className="line">
                    <span>Data de entrega</span>
                    <small>
                      <>
                        {new Date(
                          `${currentOrder.updatedAt}`
                        ).toLocaleDateString()}{" "}
                        ás <br />
                        {new Date(
                          `${currentOrder.updatedAt}`
                        ).toLocaleTimeString()}
                      </>
                    </small>
                  </div>
                )}

                <div className="client-notes">
                  <Accordion
                    defaultExpanded={true}
                    elevation={0}
                    disableGutters
                    square
                  >
                    <AccordionSummary
                      expandIcon={<FaAngleDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Observações do cliente</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{currentOrder.client_notes}</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion elevation={0} disableGutters square>
                    <AccordionSummary
                      expandIcon={<FaAngleDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        Endereço: {currentOrder.address.address}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Ponto de referência: Casa com uma árvore na frente
                        <br />
                        <br />- {currentOrder.address.city},{" "}
                        {currentOrder.address.state}
                        <br />- {currentOrder.address.country}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="line">
                  <span>Produtos</span>
                  <strong className="less">
                    {currentOrder.subtotal.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                </div>
                <div className="line">
                  <span>Entrega</span>
                  <strong className="less">
                    {currentOrder.delivery_cost.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                </div>
                <div className="line">
                  <span>Cupom</span>
                  <strong className="less">- </strong>
                </div>
                <div className="line">
                  <span>Pago com saldo</span>
                  <strong className="less">
                    -
                    {currentOrder.wallet_value_used.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                </div>
                <div style={{ marginTop: 25 }} className="line">
                  <span>Total</span>
                  <strong>
                    {currentOrder.total_value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                </div>
                <div style={{ marginTop: 30 }} className="divisor"></div>
                <div style={{ marginTop: 20 }} className="line line-red">
                  <span>Método</span>
                  <strong>Pago pelo app</strong>
                </div>

                <div className="cart">
                  <h3>Carrinho</h3>
                  <div className="cart-itens">
                    {currentOrder.cart.map((item: any, index: any) => (
                      <CartItem key={index} data={item} />
                    ))}
                  </div>
                </div>
                <div style={{ height: "100px" }}></div>
              </>
            ) : (
              <></>
            )}
          </CustomScroll>
        </div>

        <div className="client">
          {currentOrderStatus === "cart" ||
          currentOrderStatus === "waiting" ||
          currentOrderStatus === "started" ? (
            <>
              <div
                style={{ zIndex: 99, padding: 50, position: "absolute" }}
                className="client-info"
              >
                <div className="client-top-head">
                  <h2>Cliente</h2>
                </div>

                <div className="line">
                  <small>Nome:</small>
                  <span>Isaque Heider</span>
                </div>
                <div className="line">
                  <small>Email:</small>
                  <span>isaque@gmail.com</span>
                </div>
                <div className="line">
                  <small>CPF:</small>
                  <span>123.456.789-99</span>
                </div>
                <div className="line">
                  <small>Celular:</small>
                  <span>(82) 99999-3333</span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <MapComponent
            handleFinish={setOrderAsFinished}
            finished={currentOrderStatus === "finished"}
            disabled={
              currentOrderStatus !== "road" &&
              currentOrderStatus !== "delivered" &&
              currentOrderStatus !== "finished"
            }
          />
        </div>
      </Container>
    </WithMenuContainer>
  );
};

export default Orders;
