import React from 'react';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';

import { Container } from './styles';
import { useEffect } from 'react';

import mapMarker from '../../../../assets/map-marker.png';
import mapCurrentMarker from '../../../../assets/map-current.png';
import { FaCircle } from 'react-icons/fa';

export interface MapPageProps {
  finished?: boolean;
  handleFinish?: any;
}

const MapPage: React.FC<MapPageProps> = ({ finished, handleFinish }) => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const [searchBoxA, setSearchBoxA] =
    React.useState<google.maps.places.SearchBox>();
  const [searchBoxB, setSearchBoxB] =
    React.useState<google.maps.places.SearchBox>();
  const [pointA, setPointA] = React.useState<google.maps.LatLngLiteral>();
  const [currentPlace, setCurrentPlace] =
    React.useState<google.maps.LatLngLiteral>();
  const [pointB, setPointB] = React.useState<google.maps.LatLngLiteral>();

  const [origin, setOrigin] = React.useState<google.maps.LatLngLiteral | null>(
    null
  );

  const [markers, setMarkers] = React.useState<google.maps.LatLngLiteral[]>([]);

  const [destination, setDestination] =
    React.useState<google.maps.LatLngLiteral | null>(null);

  const [response, setResponse] =
    React.useState<google.maps.DistanceMatrixResponse | null>(null);

  const [isFinishedCheck, setIsFinishedCheck] = React.useState(false);

  const [position, setPosition] = React.useState({
    lat: -22.95637377920345,
    lng: -43.198701957803095,
  });

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
    traceRoute();
  };

  const onLoadA = (ref: google.maps.places.SearchBox) => {
    setSearchBoxA(ref);
  };

  const onLoadB = (ref: google.maps.places.SearchBox) => {
    setSearchBoxB(ref);
  };

  const onPlacesChangedA = () => {
    const places = searchBoxA!.getPlaces();
    console.log(places);
    const place = places![0];
    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setPointA(location);
    setOrigin(null);
    setDestination(null);
    setResponse(null);
    map?.panTo(location);
  };

  const onPlacesChangedB = () => {
    const places = searchBoxB!.getPlaces();
    console.log(places);
    const place = places![0];
    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setPointB(location);
    setOrigin(null);
    setDestination(null);
    setResponse(null);
    map?.panTo(location);
  };

  const traceRoute = () => {
    if (pointA && pointB) {
    }
    setOrigin({
      lat: -22.95637377920345,
      lng: -43.198701957803095,
    });

    setDestination({
      lat: -22.955059511730056,
      lng: -43.19986088436355,
    });

    setIsFinishedCheck(false);
  };

  const changeCurrent = () => {
    if (pointA && pointB) {
    }
    if (map) {
      setPosition({
        lat: -22.906695624664514,
        lng: -43.18098181680231,
      });

      setMarkers([
        {
          lat: -22.906695624664514,
          lng: -43.18098181680231,
        },
      ]);
    }
  };

  const changeCurrent2 = () => {
    if (pointA && pointB) {
    }
    if (map) {
      setPosition({
        lat: -22.907695624664514,
        lng: -43.18498181680231,
      });

      setMarkers([
        {
          lat: -22.907695624664514,
          lng: -43.18498181680231,
        },
      ]);
    }
  };

  useEffect(() => {
    if (map) {
      // setPosition({
      //   lat: -22.95637377920345,
      //   lng: -43.198701957803095,
      // });
      if (finished) {
        setMarkers([
          {
            lat: -22.955059511730056,
            lng: -43.19986088436355,
          },
        ]);
      } else {
        setIsFinishedCheck(false);
        setMarkers([
          {
            lat: -22.95637377920345,
            lng: -43.198701957803095,
          },
        ]);
      }
    }
  }, [map, finished]);

  useEffect(() => {
    async function load() {
      if (isFinishedCheck) {
        await handleFinish();
      }
    }
    load();
  }, [isFinishedCheck]);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (origin) {
        setMarkers((old: any) => {
          if (old.length > 0) {
            const oldItem = old[0];
            if (
              oldItem.lat > -22.955059511730056 &&
              oldItem.lng - -43.19986088436355
            ) {
              setIsFinishedCheck(true);
              // alert('finished');
              return [oldItem];
            }

            return [
              {
                ...oldItem,
                lat: oldItem.lat + 0.0000121,
                lng: oldItem.lng - 0.00001,
              },
            ];
          } else {
            return [];
          }
        });

        // if (isFinishedCheck) {
        //   alert('finished');
        //   // await handleFinish();
        // }
      }
    }, 500);

    return () => clearInterval(interval);
  }, [origin, finished]);

  const directionsServiceOptions =
    // @ts-ignore
    React.useMemo<google.maps.DirectionsRequest>(() => {
      return {
        origin,
        destination,
        travelMode: 'DRIVING',
      };
    }, [origin, destination]);

  const directionsCallback = React.useCallback((res: any) => {
    if (res !== null && res.status === 'OK') {
      setResponse(res);
    } else {
      console.log(res);
    }
  }, []);

  const directionsRendererOptions = React.useMemo<any>(() => {
    return {
      options: {
        polylineOptions: {
          strokeColor: 'red',
          strokeWeight: 6,
        },
        markerOptions: {
          zIndex: 98,
          icon: {
            url: mapMarker,
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(20, 20),
            size: new window.google.maps.Size(20, 20),
          },
        },
      },
      directions: response,
    };
  }, [response]);

  return (
    <GoogleMap
      onLoad={onMapLoad}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={position}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        backgroundColor: '#f5f5f5',
        mapId: 'badec04b4149d853',
        streetViewControl: false,
        zoomControl: false,
      }}
    >
      <div className="driver-info">
        <div className="driver-info-content">
          <div className="left">
            <strong>João Paulo</strong>
            <span>
              <small>A 2km de distância</small>
            </span>
          </div>
          <div className="right">
            <strong>6m</strong>
            <span>
              <small>20h23</small>
            </span>
          </div>
        </div>
      </div>

      {markers.map((markerOption, index) => (
        <Marker
          icon={{
            url: mapCurrentMarker,
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(30, 30),
            size: new window.google.maps.Size(1000, 1000),
          }}
          zIndex={99}
          key={index}
          position={markerOption}
        />
      ))}
      {!response && pointA && (
        <Marker
          icon={{
            url: mapMarker,
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(20, 20),
            size: new window.google.maps.Size(20, 20),
          }}
          position={pointA}
        />
      )}
      {!response && pointB && (
        <Marker
          icon={{
            url: mapMarker,
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(20, 20),
            size: new window.google.maps.Size(20, 20),
          }}
          position={pointB}
        />
      )}

      {origin && destination && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}

      {response && directionsRendererOptions && (
        <DirectionsRenderer options={directionsRendererOptions} />
      )}
    </GoogleMap>
  );
};

interface MapComponentProps {
  disabled?: boolean;
  finished?: boolean;
  handleFinish?: any;
}

const MapComponent: React.FC<MapComponentProps> = ({
  disabled,
  finished,
  handleFinish,
}) => {
  return (
    <>
      <Container className="map">
        {!disabled && (
          <MapPage handleFinish={handleFinish} finished={finished} />
        )}
      </Container>
    </>
  );
};

export default React.memo(MapComponent);
