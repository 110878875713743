import styled, { createGlobalStyle } from "styled-components";

import SatoshiRegular from "../assets/Satoshi-Regular.otf";
import SatoshiMedium from "../assets/Satoshi-Medium.otf";
import SatoshiBold from "../assets/Satoshi-Bold.otf";
import SatoshiBlack from "../assets/Satoshi-Black.otf";

import ChillaxRegular from "../assets/Chillax-Regular.otf";
import ChillaxMedium from "../assets/Chillax-Medium.otf";
import ChillaxSemibold from "../assets/Chillax-Semibold.otf";
import ChillaxBold from "../assets/Chillax-Bold.otf";

export const WithMenuContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 230px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "menu content";
`;

export default createGlobalStyle`
    
    


  
    
    * {
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
    }




    html, body, #root {
        height: 100%;
        width: 100%;
    }

    .cta-main {
        height: 56px;
        min-width: 120px;
        padding: 0 10px;
        background: #FF0018;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;

        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 15px;
        text-align: center;

        color: #FFFFFF;
        text-decoration: none;

    }

    .cta-secoundary {
        height: 56px;
        min-width: 120px;
        padding: 0 10px;
        background: #181818;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;

        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 15px;
        text-align: center;

        color: #FFFFFF;
        text-decoration: none;

    }
`;
