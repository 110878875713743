import styled from "styled-components";

import profileLogo from "../../../../assets/place-logo.jpg";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  grid-area: menu;

  padding: 50px;

  .profile {
    display: flex;
    align-items: flex-start;
    .profile-photo {
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      background: url(${profileLogo}) no-repeat;
      background-size: cover;
      background-position: center;
    }

    .profile-info {
      margin-left: 15px;
      /* width: 100px; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .place {
        font-family: "Chillax";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        /* line-height: 120%; */
        /* or 14px */

        /* display: flex; */
        /* align-items: center; */

        color: #181818;
      }

      .name {
        font-family: "Chillax";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        /* line-height: 120%; */
        /* or 14px */

        /* display: flex; */
        /* align-items: center; */

        color: #ff0018;
        margin-top: 15px;
      }
    }
  }

  nav {
    margin-top: 50px;
  }
  ul {
    list-style: none;

    li + li {
      margin-top: 30px;
    }
    li {
      a {
        color: #797979;
      }
    }
    li.active {
      a {
        color: #ff0018;
        span {
          color: #181818;
        }
      }
    }
    li {
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-decoration: none;
        span {
          font-family: Chillax, sans-serif;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          /* or 14px */
          margin-left: 15px;
        }
      }
    }
  }
`;
