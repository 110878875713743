import React, { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { Container, CustomTooltipContainer, PieChartContainer } from './styles';
import MenuWrapper from '../../components/MenuWrapper';
import { WithMenuContainer } from '../../styles/global';

import CustomScroll from 'react-custom-scroll';

import { PieChart } from 'react-minimal-pie-chart';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from 'recharts';

import { TailSpin } from 'react-loader-spinner';

interface OrderAndSellsCircularChartProps {
  total: any;
  target: any;
}

const lastMonthsOrdersData = [
  {
    name: 'Nov.',
    orders: 300,
    renue: 29000,
  },
  {
    name: 'Dez.',
    orders: 320,
    renue: 31000,
  },
  {
    name: 'Jan.',
    orders: 190,
    renue: 15000,
  },
  {
    name: 'Fev.',
    orders: 199,
    renue: 17000,
  },
  {
    name: 'Mar.',
    orders: 310,
    renue: 30000,
  },
  {
    name: 'Abr.',
    orders: 313,
    renue: 31500,
  },
];

const mostSoldProductsData = [
  {
    name: 'Lata de coca-cola 350ml'.slice(0, 7) + '...',
    bigName: 'Lata de coca-cola 350ml',
    sells: 100,
  },
  {
    name: 'Garrafa de coca-cola 2L'.slice(0, 7) + '...',
    bigName: 'Garrafa de coca-cola 2L',
    sells: 130,
  },
  {
    name: 'X Burger Big Boss'.slice(0, 7) + '...',
    bigName: 'X Burger Big Boss',
    sells: 200,
  },
];

const mostRentableProductsData = [
  {
    name: 'Lata de coca-cola 350ml'.slice(0, 7) + '...',
    bigName: 'Lata de coca-cola 350ml',
    renue: 1400,
  },
  {
    name: 'Garrafa de coca-cola 2L'.slice(0, 7) + '...',
    bigName: 'Garrafa de coca-cola 2L',
    renue: 3000,
  },
  {
    name: 'X Burger Big Boss'.slice(0, 7) + '...',
    bigName: 'X Burger Big Boss',
    renue: 4130,
  },
];

const LastMonthsOrders: React.FC = () => {
  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <p>{payload[0].payload.name}</p>
          <div className="item">
            <span className="label">Pedidos: </span>
            <span className="value"> {payload[0].payload.orders}</span>
          </div>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lastMonthsOrdersData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={11}
          dataKey="name"
          padding={{ left: 0 }}
        />
        <YAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={11}
        />
        <Tooltip content={<CustomTooltip />} />

        <Line type="monotone" dataKey="orders" stroke="#FF0018" />
      </LineChart>
    </ResponsiveContainer>
  );
};

const LastMonthsRenues: React.FC = () => {
  const CustomCharLabelValue: React.FC<any> = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx="0px"
          dy="4px"
          fontSize={11}
          textAnchor="end"
          fontFamily="Satoshi"
          fontWeight={600}
          fill="#181818"
        >
          R$ {payload.value.toFixed(0)}
        </text>
      </g>
    );
  };

  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <p>{payload[0].payload.name}</p>
          <div className="item">
            <span className="label">Receita: </span>
            <span className="value">
              {payload[0].payload.renue.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </div>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lastMonthsOrdersData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={11}
          dataKey="name"
          //   padding={{ left: 0 }}
        />
        <YAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          tick={(props) => <CustomCharLabelValue {...props} />}
          fontSize={11}
        />
        <Tooltip content={<CustomTooltip />} />

        <Line type="monotone" dataKey="renue" stroke="#FF0018" />
      </LineChart>
    </ResponsiveContainer>
  );
};

const MostSoldProducts: React.FC = () => {
  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <p>{payload[0].payload.bigName}</p>
          <div className="item">
            <span className="label">Vendas: </span>
            <span className="value">{payload[0].payload.sells}</span>
          </div>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={mostSoldProductsData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={12}
          dataKey="name"
        />
        <YAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={12}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="sells" fill="#181818" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const MostRentableProducts: React.FC = () => {
  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <p>{payload[0].payload.bigName}</p>
          <div className="item">
            <span className="label">Faturamento: </span>
            <span className="value">
              {payload[0].payload.renue.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </div>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  const CustomCharLabelValue: React.FC<any> = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx="0px"
          dy="4px"
          fontSize={11}
          textAnchor="end"
          fontFamily="Satoshi"
          fontWeight={600}
          fill="#181818"
        >
          R$ {payload.value.toFixed(0)}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={mostRentableProductsData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          fontSize={12}
          dataKey="name"
        />
        <YAxis
          fontFamily="Satoshi"
          stroke="#181818"
          fontWeight={600}
          tick={(props) => <CustomCharLabelValue {...props} />}
          fontSize={12}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="renue" fill="#181818" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CircularConversionRate: React.FC<OrderAndSellsCircularChartProps> = ({
  total,
  target,
}) => {
  const [percentValue, setPercentValue] = useState<any>(null);

  const [data, setData] = useState<any>(null);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    console.log('circular:');
    if (total && target) {
      const percent = Number((100 * target) / Number(total));

      console.log(`percent: ${percent}`);

      setPercentValue(percent.toFixed(2) + '%');
      setData([
        { name: 'Vendas', value: percent, color: '#FF0018' },
        { name: 'Visitantes', value: 100 - percent, color: '#cecece' },
      ]);
      setIsReady(true);
    } else {
      const percent = 0;
      console.log(`percent: ${percent}`);
      setPercentValue(percent.toFixed(2) + '%');
      setData([
        { name: 'Vendas', value: percent, color: '#FF0018' },
        { name: 'Visitantes', value: 100 - percent, color: '#cecece' },
      ]);
      setIsReady(true);
    }
  }, [total, target]);

  if (!isReady) {
    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <TailSpin color="#8b8b8b" height="80%" width="80%" />
      </div>
    );
  }

  return (
    <PieChartContainer>
      <PieChart
        startAngle={-90}
        label={({ dataIndex, dataEntry }) => {
          // console.log(props);

          if (dataIndex === 0 && percentValue && data) {
            return percentValue;
          }
        }}
        labelStyle={{
          fontSize: '12px',
          fontFamily: 'Satoshi, sans-serif',
          fill: '#181818',
        }}
        totalValue={100}
        lineWidth={10}
        labelPosition={0}
        data={data}
      />
    </PieChartContainer>
  );
};

const Metrics = () => {
  return (
    <WithMenuContainer>
      <MenuWrapper />
      <Container>
        <CustomScroll className="scroll-content" flex="1">
          <h2>Métricas</h2>

          <div className="options">
            <div className="option selected">
              <span>Varejo</span>
            </div>
            <div className="option ">
              <span>Ambos</span>
            </div>
            <div className="option">
              <span>Atacado</span>
            </div>
          </div>

          <div className="content">
            <div className="status-board status-day">
              <div className="left">
                <small>Pedidos de hoje</small>
                <strong>3</strong>
              </div>
              <div className="right">
                <small>Receita de hoje</small>
                <strong>R$ 234,99</strong>
              </div>
            </div>
            <div className="status-board status-month">
              <div className="left">
                <small>Pedidos do mês</small>
                <strong>3</strong>
              </div>
              <div className="right">
                <small>Receita do mês</small>
                <strong>R$ 234,99</strong>
              </div>
            </div>

            <div className="chart-1">
              <h3>Pedidos dos últimos 6 meses</h3>
              <LastMonthsOrders />
            </div>

            <div className="chart-2">
              <h3>Receita dos últimos 6 meses</h3>
              <LastMonthsRenues />
            </div>

            <div className="chart-3">
              <h3>Produtos mais vendidos do mês</h3>
              <MostSoldProducts />
            </div>

            <div className="chart-4">
              <h3>Produtos com mais faturamento do mês</h3>
              <MostRentableProducts />
            </div>

            <aside className="sidebar">
              <div className="rate-group">
                <h3>Taxa de conversão</h3>
                <CircularConversionRate total={124624} target={24421} />
                <div className="legends">
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Visitantes</span>
                  </div>
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Que compraram</span>
                  </div>
                </div>
              </div>
              <div className="rate-group">
                <h3>Taxa de carrinhos abandonados</h3>
                <CircularConversionRate total={150} target={13} />
                <div className="legends">
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Carrinhos criados</span>
                  </div>
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Abandonados</span>
                  </div>
                </div>
              </div>
              <div className="rate-group">
                <h3>Taxa de recompra</h3>
                <CircularConversionRate total={1400} target={200} />
                <div className="legends">
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Clientes</span>
                  </div>
                  <div className="legend">
                    <div className="circle"></div>
                    <span>Que recompraram</span>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <div
            style={{ height: 50, display: 'block' }}
            className="custom-scroll-end"
          ></div>
        </CustomScroll>
      </Container>
    </WithMenuContainer>
  );
};

export default Metrics;
