import React from 'react';

import { Container } from './styles';

import { Link, useLocation } from 'react-router-dom';

import {
  FaBoxes,
  FaChartLine,
  FaCog,
  FaMotorcycle,
  FaShoppingBasket,
  FaStoreAlt,
  FaTag,
  FaUser,
  FaUsers,
  FaUsersCog,
  FaWallet,
} from 'react-icons/fa';

const MenuDesktop = () => {
  const location = useLocation();

  const setActive = (path: string) => {
    if (path === location.pathname.split('/')[1]) {
      return 'active';
    } else {
      return '';
    }
  };

  return (
    <Container>
      <div className="profile">
        <div className="profile-photo"></div>
        <div className="profile-info">
          <span className="place">Pastel do Jão</span>
          <span className="name">Thiago</span>
        </div>
      </div>

      <nav>
        <ul>
          <li className={setActive('orders')}>
            <Link to="/orders">
              <FaShoppingBasket />
              <span>Pedidos</span>
            </Link>
          </li>
          <li className={setActive('metrics')}>
            <Link to="/metrics">
              <FaChartLine />
              <span>Métricas</span>
            </Link>
          </li>
          <li className={setActive('categories')}>
            <Link to="/categories">
              <FaTag />
              <span>Categorias</span>
            </Link>
          </li>
          <li className={setActive('products')}>
            <Link to="/products">
              <FaBoxes />
              <span>Produtos</span>
            </Link>
          </li>
          <li className={setActive('equip')}>
            <Link to="/equip">
              <FaUsersCog />
              <span>Equipe</span>
            </Link>
          </li>

          <li className={setActive('delivery')}>
            <Link to="/delivery">
              <FaMotorcycle />
              <span>Entregadores</span>
            </Link>
          </li>
          <li className={setActive('pay')}>
            <Link to="/pay">
              <FaWallet />
              <span>OliverPay</span>
            </Link>
          </li>
          <li className={setActive('shop')}>
            <Link to="/shop">
              <FaStoreAlt />
              <span>Minha Loja</span>
            </Link>
          </li>
          <li className={setActive('config')}>
            <Link to="/config">
              <FaCog />
              <span>Configurações</span>
            </Link>
          </li>
          <li className={setActive('profile')}>
            <Link to="/profile">
              <FaUser />
              <span>Minha Conta</span>
            </Link>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default MenuDesktop;
