import React, { useEffect, useRef } from 'react';
import {
  Container,
  ModalVariantsContainer,
  ProductVariantOptionContainer,
} from './styles';

import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import api from '../../services/api';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import MenuWrapper from '../../components/MenuWrapper';

import CurrencyInput from 'react-currency-input-field';

import { WithMenuContainer } from '../../styles/global';
import {
  FaAngleDown,
  FaArrowLeft,
  FaBars,
  FaExchangeAlt,
  FaPlus,
} from 'react-icons/fa';
import Loader from 'react-loader-spinner';

import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import Textarea from '../../components/Textarea';
import { useState } from 'react';

import { components } from 'react-select';
import { toast } from 'react-toastify';

const CategoryDetails = () => {
  const formRef = useRef<FormHandles>(null);

  const { category_id }:any = useParams();

  const history = useHistory();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    async function load() {
      if (category_id === 'new') {
      } else {
        const { data: categoryData } = await api.get(
          `/places/6265a43d21ca6e602ea5a41e/categories/${category_id}`
        );

        if (categoryData) {
          setData(categoryData);
        }
      }
    }
    load();
  }, [category_id, formRef, setData]);

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    if (category_id === 'new') {
      const { data: responseData } = await api.post(
        `/places/6265a43d21ca6e602ea5a41e/categories`,
        formData
      );

      if (responseData) {
        setData(responseData);
        toast.success('Categoria criada', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,

          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        history.push(`/categories/${responseData._id}`);
      }
    } else {
      const { data: responseData } = await api.put(
        `/places/6265a43d21ca6e602ea5a41e/categories/${category_id}`,
        formData
      );

      if (responseData) {
        setData(responseData);
        toast.success('Categoria editada', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,

          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <WithMenuContainer>
      <MenuWrapper />

      <Container>
        <CustomScroll className="scroll-content" flex="1">
          <div className="top-head">
            <Link to="/categories ">
              <FaArrowLeft />
            </Link>
            <h2>
              {data ? (
                <>{`Editar categoria "${data?.title}"`}</>
              ) : (
                <>Criar categoria</>
              )}
            </h2>
          </div>

          <Form
            initialData={data ? data : null}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className="grid">
              <div className="input-group">
                <label htmlFor="title">* Título da categoria</label>
                <Input
                  name="title"
                  placeholder="Digite o nome da categoria"
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="description">Descrição</label>
                <Textarea
                  name="description"
                  placeholder="Digite a descrição da sua categoria"
                />
                <small>
                  Descrição que pode ser exibida em alguns momentos no app se
                  existente.
                </small>
              </div>
            </div>

            <div className="divider"></div>

            <div className="bottom-buttons">
              {/* <button className="cta-secoundary">Deletar</button> */}
              <button className="cta-main">Salvar</button>
            </div>
          </Form>
          <div style={{ height: 50 }} className="end-scroll"></div>
        </CustomScroll>
      </Container>
    </WithMenuContainer>
  );
};

export default CategoryDetails;
