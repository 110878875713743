import styled, { css } from 'styled-components';

import burgerImg from '../../assets/burger.png';

export const Container = styled.div`
  grid-area: content;
  position: relative;
  /* padding: 50px; */
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: 100%;
  h1 {
    color: #000;
    font-size: 11px;
  }

  .scroll-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .rcs-inner-container {
      padding: 50px 50px 50px 0;
    }
    .rcs-inner-handle {
      background: #d2d2d2;
    }
  }

  .options {
    margin: 0 auto;
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 30px;
    height: 40px;
    /* background: #fff; */
    /* padding: 0 20px; */
    border: 2px solid #ddd;
    border-radius: 20px;
    .option.selected {
      background: #fff;
      border-color: #ff0018;
      span {
        color: #181818;
      }
    }

    .option {
      height: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      cursor: pointer;
      border: 2px solid transparent;
      span {
        font-family: 'Satoshi';
        font-style: normal;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #181818;
      }
    }
  }

  .content {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1.4fr 1.4fr 1fr;
    grid-template-rows: 110px 330px 330px 1fr;
    grid-template-areas:
      'status1 status2 sidebar'
      'chart1 chart2 sidebar'
      'chart3 chart4 sidebar'
      '. . sidebar';
    grid-gap: 20px;

    aside.sidebar {
      grid-area: sidebar;
      background: #fff;
      height: fit-content;
      padding: 30px;

      .rate-group + .rate-group {
        margin-top: 30px;
      }

      .rate-group {
        display: flex;
        flex-direction: column;

        .legends {
          display: inline-block;
          text-align: center;
          margin-top: 20px;
          .legend:nth-child(2) {
            span {
              color: #ff0018;
            }
            .circle {
              background: #ff0018;
            }
          }
          .legend {
            margin: 10px;
            display: inline-flex;
            align-items: center;
            .circle {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: #cecece;
              margin-right: 10px;
            }

            span {
              font-family: 'Satoshi';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 14px;

              color: #646464;
            }
          }
        }
      }
    }

    h3 {
      margin-bottom: 20px;
    }

    .chart-1 {
      width: 100%;
      height: 100%;
      grid-area: chart1;
    }
    .chart-2 {
      width: 100%;
      height: 100%;
      grid-area: chart2;
    }
    .chart-3 {
      width: 100%;
      height: 100%;
      grid-area: chart3;
    }
    .chart-4 {
      width: 100%;
      height: 100%;
      grid-area: chart4;
    }

    .status-board {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;

      small {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
      }

      strong {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 100%;
        margin-top: 15px;
      }

      .left,
      .right {
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: center; */
      }

      .left {
        width: 50%;
        background: #fff;

        small {
          color: #646464;
        }

        strong {
          color: #181818;
        }
      }

      .right {
        width: 50%;
        background: #181818;
        small {
          color: #aaa;
        }
        strong {
          color: #fff;
        }
      }
    }
  }

  .top-head {
    display: flex;
    cursor: pointer;
    align-items: center;
    h2 {
      margin-left: 15px;
    }
  }

  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
  h3 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
`;

export const CustomTooltipContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  p {
    font-family: 'Oxygen', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px !important;
    text-align: center;
    line-height: 100%;
    text-transform: uppercase;
    color: #181818;
    margin-top: 10px;
  }
  .item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-beetwen;
    margin-top: 20px;
  }
  span {
    font-family: 'Oxygen', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px !important;
    line-height: 100%;
    text-transform: uppercase;
    color: #181818;
  }
  span.label {
    margin-right: 10px;
  }
  span.value {
    color: #ff0018;
    font-weight: 900;
    text-align: right !important;
    margin-left: auto;
  }
`;

export const PieChartContainer = styled.div`
  width: 100%;
  /* height: 300px; */
  display: flex;
  /* align-items: flex-start; */
  /* height: auto; */
  font-family: 'Oxygen', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #181818;
  /* margin: 30px 0; */
  margin-top: 20px;
  /* margin-bottom: 30px; */
  padding: 0 20px;

  .recharts-responsive-container {
    margin: 0 auto;
  }
`;

export const ChartLabel = styled.div`
  color: #181818;
`;
