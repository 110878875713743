import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.clubedooliver.com',
  headers: {
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNjVhM2M4MWRlODUwNDAwMWU0ZWY2MSIsImlhdCI6MTY1MTA5OTcxNCwiZXhwIjoxNjU5OTg2MTE0fQ.BMVL68zYYBxRe_7xdYmvmYkrfMfQmH7itUgprxBkscs',
  },
});

export default api;
