import React, { useEffect, useRef } from 'react';
import {
  Container,
  ModalVariantsContainer,
  ProductVariantOptionContainer,
} from './styles';

import { SubmitHandler, FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';

import { Link, useHistory, useParams } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import MenuWrapper from '../../components/MenuWrapper';

import CurrencyInput from 'react-currency-input-field';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { WithMenuContainer } from '../../styles/global';
import {
  FaAngleDown,
  FaArrowLeft,
  FaBars,
  FaExchangeAlt,
  FaPlus,
} from 'react-icons/fa';
import Loader from 'react-loader-spinner';

import Input from '../../components/Input';
import AsyncSelect from 'react-select/async';
import Textarea from '../../components/Textarea';
import { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { components } from 'react-select';
import api from '../../services/api';
import { toast } from 'react-toastify';
const ProductDetails = () => {
  const formRef = useRef<FormHandles>(null);
  const variantsModalForm = useRef<FormHandles>(null);

  const { product_id }:any = useParams();

  const history = useHistory();

  const [selectedVariantModal, setSelectedVariantModal] = useState<any>(null);

  const [data, setData] = useState<any>(null);

  const [productOptions, setProductOptions] = useState<any>([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (ind: number, optionInd: number, value?: any) => {
    setSelectedVariantModal([ind, optionInd]);

    setOptionPrice({
      value,
      float: value,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [oldPrice, setOldPrice] = useState({
    float: 0,
    value: 0,
  });
  const [currentPrice, setCurrentPrice] = useState({
    float: 0,
    value: 0,
  });
  const [optionPrice, setOptionPrice] = useState({
    float: 0,
    value: 0,
  });

  const [productType, setProductType] = useState('single');

  const [options, setOptions] = useState<any>([]);
  const [optionSelected, setOptionSelected] = useState<any>(null);

  const [generalCategoryOptions, setGeneralCategoryOptions] = useState<any>([]);
  const [generalCategoryOptionSelected, setGeneralCategoryOptionSelected] =
    useState<any>(null);

  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [categoryOptionSelected, setCategoryOptionSelected] =
    useState<any>(null);

  useEffect(() => {
    async function load() {
      const { data: geralCategories } = await api.get(`/geral-categories`);

      setGeneralCategoryOptions(
        geralCategories.map((cat: any) => ({
          label: cat.title,
          value: cat._id,
        }))
      );

      const { data: placeCategories } = await api.get(
        `/places/6265a43d21ca6e602ea5a41e/categories`
      );

      setCategoryOptions(
        placeCategories.map((cat: any) => ({
          label: cat.title,
          value: cat._id,
        }))
      );

      const { data: placeCatalogSections } = await api.get(
        `/places/6265a43d21ca6e602ea5a41e/catalog_sections`
      );

      setOptions(
        placeCatalogSections.map((cat: any) => ({
          label: cat.title,
          value: cat._id,
        }))
      );

      if (product_id === 'new') {
      } else {
        const { data: productData } = await api.get(
          `/places/6265a43d21ca6e602ea5a41e/products/${product_id}`
        );

        if (productData) {
          setData(productData);

          const productOptionsNew = productData.options.map((option: any) => ({
            ...option,
            key: uuidv4(),
            description: option.description || '',
          }));

          const [currentGeneralOption] = geralCategories.filter(
            (i: any) => i._id === productData.geral_category_id
          );
          setGeneralCategoryOptionSelected({
            label: currentGeneralOption.title,
            value: currentGeneralOption._id,
          });

          const [currentPlaceCategory] = placeCategories.filter(
            (i: any) => i._id === productData.category_id
          );
          setCategoryOptionSelected({
            label: currentPlaceCategory.title,
            value: currentPlaceCategory._id,
          });

          if (productData.catalog_section_id) {
            const [currentCatalogSection] = placeCatalogSections.filter(
              (i: any) => i._id === productData.catalog_section_id
            );
            setOptionSelected({
              label: currentCatalogSection.title,
              value: currentCatalogSection._id,
            });
          }

          setProductOptions(productOptionsNew);
          setProductType(productData.is_wholesale ? 'wholesale' : 'single');
          setOldPrice({
            float: productData.old_price,
            value: productData.old_price,
          });
          setCurrentPrice({
            float: productData.price,
            value: productData.price,
          });

          if (formRef.current) {
            formRef.current.setData({
              ...productData,
              options: productOptionsNew,
            });
          }
        }
      }
    }
    load();
  }, [product_id, formRef, setData]);

  const filterColors = (inputValue: string) => {
    return options.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const filterCategoryOptions = (inputValue: string) => {
    return categoryOptions.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const filterGeneralCategoryOptions = (inputValue: string) => {
    return generalCategoryOptions.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const loadCategoryOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(() => {
      callback(filterCategoryOptions(inputValue));
    }, 1000);
  };
  const loadGeneralCategoryOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(() => {
      callback(filterGeneralCategoryOptions(inputValue));
    }, 1000);
  };

  const changeOptionTypes = (index: number, alt: string, value: any) => {
    var oldData = [...productOptions];

    var oldData = oldData.map((option: any, ind: number) => {
      if (ind === index) {
        const payload = {
          ...option,
        };

        payload[alt] = value;

        return payload;
      } else {
        return option;
      }
    });

    setProductOptions(oldData);
  };

  const customStylesInputModal = {
    option: (provided: any, state: any) => ({
      ...provided,
      //
      // color: "#ffffff",
      // borderWidth: "0px",
      // boxShadow: "none",
      // fontFamily: "Oxygen",
      // fontWeight: "bold",
      //
      // fontSize: 12,
    }),
    container: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: '100%',
      zIndex: 98,
      // marginBottom: 20,
    }),
    indicatorSeparator: (provided: any) => ({
      // none of react-select's styles are passed to <Control />

      display: 'none',
    }),
    control: (provided: any) => ({
      // ...provided,
      // none of react-select's styles are passed to <Control />
      width: '100%',
      display: 'flex',
      zIndex: 98,
      background: '#eeeeee',
      padding: '5px 15px',
      // marginBottom: 20,
    }),
    valueContainer: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      padding: 0,
      // background: "#9D9D9D",
    }),
    dropdownIndicator: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      position: 'relative',
      paddingLeft: 20,
      // background: "#9D9D9D",
    }),
    menu: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      ...provided,
      // paddingLeft: "10px",
      // background: "#9D9D9D",
    }),
    menuList: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 100,
      // display: "flex"
      // ...provided,
      // paddingLeft: "10px",
      ...provided,
      // background: "#9D9D9D",
    }),
    singleValue: (provided: any, state: any) => {
      // const opacity = state.isDisabled ? 0.5 : 1;
      // const transition = "opacity 300ms";

      return {
        ...provided,
      };
    },
  };

  interface ProductVariantOptionProps {
    title: string;
    ind: number;
    optionInd: number;
    value?: any;
  }

  const ProductVariantOption: React.FC<ProductVariantOptionProps> = ({
    title,
    value,
    ind,
    optionInd,
  }) => {
    return (
      <>
        <ProductVariantOptionContainer>
          <div className="content">
            <div
              onClick={() => handleClickOpen(ind, optionInd, value)}
              className="action"
            >
              <FaBars />
            </div>
            <strong>{title}</strong>
          </div>
          {value > 0 && (
            <div className="option-price">
              <small>
                {value.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </small>
            </div>
          )}
        </ProductVariantOptionContainer>
      </>
    );
  };

  const OptionVariantDelete = () => {
    if (selectedVariantModal.length > 1) {
      var oldData = [...productOptions];

      oldData = oldData.map((option: any, index: number) => {
        if (index === selectedVariantModal[0]) {
          var oldVariants = [...option.variants];

          oldVariants = oldVariants.filter(
            (variant, ind: number) => ind !== selectedVariantModal[1]
          );

          return {
            ...option,
            variants: oldVariants,
          };
        } else {
          return option;
        }
      });

      setProductOptions(oldData);
      setOpen(false);
    }
  };

  const VariantModalFormSubmit = () => {
    if (variantsModalForm.current) {
      const formData = variantsModalForm.current?.getData();

      const payload = {
        ...formData,
        price: optionPrice.float,
      };

      console.log(payload);

      var oldData = [...productOptions];

      console.log(selectedVariantModal);
      if (selectedVariantModal.length > 1) {
        oldData = oldData.map((option: any, index: number) => {
          if (index === selectedVariantModal[0]) {
            var oldVariants = [...option.variants];

            oldVariants = oldVariants.map((variant: any, ind: number) => {
              if (ind === selectedVariantModal[1]) {
                return {
                  ...variant,
                  ...payload,
                };
              } else {
                return variant;
              }
            });

            return {
              ...option,
              variants: oldVariants,
            };
          } else {
            return option;
          }
        });
      } else {
        oldData = oldData.map((option: any, index: number) => {
          if (index === selectedVariantModal[0]) {
            const newVariants = [...option.variants];
            newVariants.push(payload);

            return {
              ...option,
              variants: newVariants,
            };
          } else {
            return option;
          }
        });
      }

      console.log(oldData);

      setProductOptions(oldData);
      setOpen(false);
    }
  };

  const DeleteOption = (key: any) => {
    setSelectedVariantModal(null);
    var oldData = [...productOptions];

    oldData = oldData.filter((option: any) => {
      if (option.key === key) {
        return false;
      }
      return true;
    });

    setProductOptions([]);
    setProductOptions(oldData);
  };

  const AddOption = () => {
    setSelectedVariantModal(null);
    const oldData = [...productOptions];

    oldData.push({
      name: '',
      description: '',
      required: false,
      type: 'single',
      variants: [],
      key: uuidv4(),
    });

    setProductOptions([]);
    setProductOptions(oldData);
  };

  const changeOptionBaseInfo = (key: string, info: string, value: string) => {
    var oldData = [...productOptions];

    oldData = oldData.map((option: any) => {
      if (option.key === key) {
        const payload = {
          ...option,
        };

        payload[info] = value;
        return payload;
      } else {
        return option;
      }
    });

    setProductOptions([]);
    setProductOptions(oldData);
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#181818',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#ff0018',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  const handleSubmitAll = async (formData: any) => {
    if (!categoryOptionSelected || !generalCategoryOptionSelected) {
      toast.error(
        'Seu produto deve ter uma categoria de seu comércio e do app.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,

          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      return null;
    }

    const payload = {
      title: formData.title,
      description: formData.description,
      old_price: oldPrice.float,
      price: currentPrice.float,
      type: productType,
      catalog_section_id: optionSelected.value,
      geral_category_id: generalCategoryOptionSelected.value,
      category_id: categoryOptionSelected.value,
      options: productOptions,
      images: [
        'https://assets.unileversolutions.com/recipes-v2/230411.jpg',
        'https://soubh.uai.com.br/uploads/post/image/11558/main_destaque_Alexandr_Popel.jpg',
      ],
    };

    var check = [];

    if (
      payload.options.map((option: any) => {
        if (option.variants.length === 0) {
          check.push(option);
        }
      })
    )
      if (check.length > 0) {
        toast.error(
          'Uma opcão de produto deve ter variações, adicione-as ou exclua a opção.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,

            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        return null;
      }

    console.log(payload);

    if (product_id === 'new') {
      const { data: responseData } = await api.post(
        `/places/6265a43d21ca6e602ea5a41e/products`,
        payload
      );

      if (responseData) {
        toast.success('Produto criado com sucesso.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,

          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        history.push(`/products/${responseData._id}`);
      }
    } else {
      const { data: responseData } = await api.put(
        `/places/6265a43d21ca6e602ea5a41e/products/${product_id}`,
        payload
      );

      if (responseData) {
        toast.success('Produto editado com sucesso.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,

          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <WithMenuContainer>
      <MenuWrapper />
      <ThemeProvider theme={theme}>
        <Dialog className="modal-variants" open={open} onClose={handleClose}>
          <DialogContent>
            <Form
              initialData={
                (selectedVariantModal &&
                  selectedVariantModal.length > 1 &&
                  productOptions[selectedVariantModal[0]].variants[
                    selectedVariantModal[1]
                  ]) ||
                null
              }
              ref={variantsModalForm}
              onSubmit={() => {}}
            >
              <ModalVariantsContainer>
                <h2>Opção</h2>
                <div style={{ marginBottom: 0 }} className="input-group">
                  <label>Imagem em destaque da opção (opcional)</label>
                  <label htmlFor="contained-button-file">
                    <input
                      style={{ display: 'none' }}
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
                <div className="input-group">
                  <label htmlFor="name">* Nome da opção</label>
                  <Input
                    name={`name`}
                    placeholder="Digite o nome da opção"
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="description">Descrição (opcional)</label>
                  <Input
                    name={`description`}
                    placeholder="Digite uma descrição para a opção"
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="old_price">Preço da opção (opcional)</label>
                  <CurrencyInput
                    decimalsLimit={2}
                    decimalSeparator=","
                    allowNegativeValue={false}
                    placeholder="Preço opcional da opção"
                    groupSeparator="."
                    prefix="R$"
                    required
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    name="price"
                    defaultValue={0}
                    value={optionPrice.value}
                    onValueChange={(value: any, b: any, c: any) =>
                      setOptionPrice({ float: c.float, value: value })
                    }
                  />
                </div>
              </ModalVariantsContainer>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            {selectedVariantModal && selectedVariantModal.length > 1 && (
              <Button
                type="submit"
                color="primary"
                onClick={OptionVariantDelete}
              >
                Deletar
              </Button>
            )}
            <Button
              type="submit"
              color="secondary"
              onClick={VariantModalFormSubmit}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Container>
        <CustomScroll className="scroll-content" flex="1">
          <div className="top-head">
            <Link to="/products ">
              <FaArrowLeft />
            </Link>
            <h2>
              {product_id !== 'new' && data ? (
                <>Editar produto "{data.title}"</>
              ) : (
                <>Criar produto</>
              )}
            </h2>
          </div>

          <Form ref={formRef} onSubmit={handleSubmitAll}>
            <div className="grid">
              <div className="input-group">
                <label htmlFor="title">* Título do produto</label>
                <Input
                  name="title"
                  placeholder="Digite o nome do produto que será exibido no catálogo"
                  required
                />
              </div>

              <div className="input-row row-prices">
                <div className="input-group">
                  <label htmlFor="old_price">Preço antigo</label>
                  <CurrencyInput
                    decimalsLimit={2}
                    decimalSeparator=","
                    allowNegativeValue={false}
                    placeholder="Preço antigo"
                    groupSeparator="."
                    prefix="R$"
                    required
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    name="old_price"
                    value={oldPrice.value}
                    defaultValue={0}
                    onValueChange={(value: any, b: any, c: any) =>
                      setOldPrice({ float: c.float, value: value })
                    }
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="current_price">* Preço atual</label>
                  <CurrencyInput
                    decimalsLimit={2}
                    decimalSeparator=","
                    allowNegativeValue={false}
                    placeholder="* Preço atual"
                    groupSeparator="."
                    prefix="R$"
                    required
                    className="overwrite-input"
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    name="current_price"
                    value={currentPrice.value}
                    defaultValue={0}
                    onValueChange={(value: any, b: any, c: any) =>
                      setCurrentPrice({ float: c.float, value: value })
                    }
                  />
                </div>
              </div>

              <div className="input-select-group">
                <label htmlFor="catalog_section">Seção do catálogo</label>
                <AsyncSelect
                  placeholder="Escolha a seção do catálogo que o produto será exibido."
                  styles={customStylesInputModal}
                  className="input-select-wrap"
                  value={optionSelected}
                  onChange={(e) => setOptionSelected(e)}
                  classNamePrefix="input-select"
                  name="catalog_section"
                  loadOptions={loadOptions}
                  components={{
                    DropdownIndicator: (props: any) => (
                      <components.DropdownIndicator {...props}>
                        <FaAngleDown />
                      </components.DropdownIndicator>
                    ),

                    LoadingIndicator: (props: any) => (
                      <components.DropdownIndicator {...props}>
                        <div
                          className="add-product-svg"
                          style={{
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {/* <Loader
                        type="TailSpin"
                        color="#8b8b8b"
                        width={15}
                        height={15}
                      /> */}
                        </div>
                      </components.DropdownIndicator>
                    ),
                  }}
                  defaultOptions={options}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#2c2c2c',

                      primary75: '#181818',
                      primary50: '#ddd',
                      primary25: '#eee',
                    },
                  })}
                />
                <small>Controle as ordens dos produtos na loja</small>
              </div>
              <div className="input-row row-categories">
                <div className="input-select-group">
                  <label htmlFor="shop_category">* Categoria da loja</label>
                  <AsyncSelect
                    name="shop_category"
                    placeholder="Escolha a categoria do seu produto."
                    styles={customStylesInputModal}
                    className="input-select-wrap"
                    classNamePrefix="input-select"
                    value={categoryOptionSelected}
                    onChange={(e) => setCategoryOptionSelected(e)}
                    loadOptions={loadCategoryOptions}
                    components={{
                      DropdownIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <FaAngleDown />
                        </components.DropdownIndicator>
                      ),

                      LoadingIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <div
                            className="add-product-svg"
                            style={{
                              height: '100%',
                              position: 'absolute',
                              top: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* <Loader
                        type="TailSpin"
                        color="#8b8b8b"
                        width={15}
                        height={15}
                      /> */}
                          </div>
                        </components.DropdownIndicator>
                      ),
                    }}
                    defaultOptions={categoryOptions}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#2c2c2c',

                        primary75: '#181818',
                        primary50: '#ddd',
                        primary25: '#eee',
                      },
                    })}
                  />
                  <small>
                    As categorias dos produtos são definias em "Categorias"
                  </small>
                </div>

                <div className="input-select-group">
                  <label htmlFor="app_category">* Categoria no app</label>
                  <AsyncSelect
                    name="app_category"
                    placeholder="Escolha a categoria do seu produto."
                    styles={customStylesInputModal}
                    className="input-select-wrap"
                    value={generalCategoryOptionSelected}
                    onChange={(e) => setGeneralCategoryOptionSelected(e)}
                    classNamePrefix="input-select"
                    loadOptions={loadGeneralCategoryOptions}
                    components={{
                      DropdownIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <FaAngleDown />
                        </components.DropdownIndicator>
                      ),

                      LoadingIndicator: (props: any) => (
                        <components.DropdownIndicator {...props}>
                          <div
                            className="add-product-svg"
                            style={{
                              height: '100%',
                              position: 'absolute',
                              top: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* <Loader
                        type="TailSpin"
                        color="#8b8b8b"
                        width={15}
                        height={15}
                      /> */}
                          </div>
                        </components.DropdownIndicator>
                      ),
                    }}
                    defaultOptions={generalCategoryOptions}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#2c2c2c',

                        primary75: '#181818',
                        primary50: '#ddd',
                        primary25: '#eee',
                      },
                    })}
                  />
                  <small>
                    Selecione uma das categorias definidas pelo aplicativo
                  </small>
                </div>
              </div>

              <div className="input-group">
                <label htmlFor="description">* Descrição</label>
                <Textarea
                  name="description"
                  placeholder="Digite a descrição do seu produto"
                  required
                />
                <small>
                  Descrição que será exibido abaixo ao título do produto
                </small>
              </div>

              <div className="input-row">
                <div className="product-type-group input-group">
                  <label>* Tipo de produto</label>

                  <div className="options">
                    <div
                      onClick={() => setProductType('wholesale')}
                      className={`type-option ${
                        productType === 'wholesale' ? 'selected' : ''
                      }`}
                    >
                      Atacado
                    </div>
                    <div
                      onClick={() => setProductType('single')}
                      className={`type-option ${
                        productType === 'single' ? 'selected' : ''
                      }`}
                    >
                      Varejo
                    </div>
                  </div>
                  <small>Consulte a disponibilidade das opções</small>
                </div>
              </div>
            </div>

            <div className="product-variants">
              <div className="product-variants-head">
                <div className="titles">
                  <label>Opções de escolhas do produto</label>
                  <small>
                    Adicione opções ao seu produto. Ex.: Sabores, recheios, pães
                    e etc
                  </small>
                </div>
                <div onClick={() => AddOption()} className="add-button">
                  <FaPlus />
                </div>
              </div>
              <div className="product-variants-list">
                {productOptions.map((productOption: any, index: any) => (
                  <Scope path={`variants_list`}>
                    <div className="product-variant-item">
                      <div className="input-group product-variant-group">
                        <label htmlFor="name">* Nome da opção</label>
                        <input
                          name="name"
                          value={productOption?.name}
                          onChange={(e) =>
                            changeOptionBaseInfo(
                              productOption.key,
                              'name',
                              e.target.value
                            )
                          }
                          placeholder="Título da opção"
                          required
                        />
                        <label style={{ marginTop: 20 }} htmlFor="description">
                          Descrição
                        </label>
                        <input
                          name="description"
                          onChange={(e) =>
                            changeOptionBaseInfo(
                              productOption.key,
                              'description',
                              e.target.value
                            )
                          }
                          defaultValue={productOption?.description || ''}
                          placeholder="Descrição da opção"
                        />
                        <button
                          onClick={() => DeleteOption(productOption.key)}
                          className="btn-black"
                        >
                          Deletar opcão
                        </button>
                      </div>

                      <div className="options-wrap product-variant-group">
                        <label>
                          * Opções ({productOption.variants.length} de 15)
                        </label>
                        <div className="wrap">
                          <ProductVariantOptionContainer
                            onClick={() => {
                              setSelectedVariantModal([index]);
                              setOptionPrice({ float: 0, value: 0 });
                              setOpen(true);
                            }}
                          >
                            <div
                              style={{
                                cursor: 'pointer',
                                paddingLeft: 20,
                                justifyContent: 'center',
                              }}
                              className="content"
                            >
                              <strong>Adicionar</strong>
                            </div>
                          </ProductVariantOptionContainer>
                          {productOption.variants.map(
                            (variant: any, variantInd: number) => (
                              <ProductVariantOption
                                ind={index}
                                optionInd={variantInd}
                                title={variant.name}
                                value={variant.price}
                              />
                            )
                          )}
                        </div>
                        <small className="wrap-small">
                          Clique em uma opcão para adicionar preços, imagens ou
                          deletar
                        </small>
                      </div>

                      <div className="input-group option-type-group product-variant-group">
                        <label>* Tipo de seleção</label>
                        <small>
                          Define se o cliente pode selecionar um ou mais itens
                          de uma opção
                        </small>

                        <div className="selection-types">
                          <div
                            onClick={() =>
                              changeOptionTypes(index, 'type', 'single')
                            }
                            className={`selection-type ${
                              productOption.type === 'single' ? 'selected' : ''
                            }`}
                          >
                            <div className="radio"></div>
                            <strong>Único item</strong>
                          </div>
                          <div
                            onClick={() =>
                              changeOptionTypes(index, 'type', 'multiple')
                            }
                            className={`selection-type ${
                              productOption.type === 'multiple'
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <div className="radio"></div>
                            <strong>Múltiplos itens</strong>
                          </div>
                        </div>
                        <label style={{ marginTop: 30, marginBottom: 0 }}>
                          * É obrigatório?
                        </label>

                        <div className="selection-types">
                          <div
                            onClick={() =>
                              changeOptionTypes(index, 'required', true)
                            }
                            className={`selection-type ${
                              productOption.required === true ? 'selected' : ''
                            }`}
                          >
                            <div className="radio"></div>
                            <strong>Sim</strong>
                          </div>
                          <div
                            onClick={() =>
                              changeOptionTypes(index, 'required', false)
                            }
                            className={`selection-type ${
                              productOption.required === false ? 'selected' : ''
                            }`}
                          >
                            <div className="radio"></div>
                            <strong>Não</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                  </Scope>
                ))}
              </div>
            </div>

            <div className="bottom-buttons">
              {/* <button className="cta-secoundary">Deletar</button> */}
              <button className="cta-main">Salvar</button>
            </div>
          </Form>
          <div style={{ height: 50 }} className="end-scroll"></div>
        </CustomScroll>
      </Container>
    </WithMenuContainer>
  );
};

export default ProductDetails;
