import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Orders from './pages/Orders';
import Metrics from './pages/Metrics';
import CategoryDetails from './pages/CategoryDetails';
import Categories from './pages/Categories';
const Home = () => {
  return <h1>Home</h1>;
};

export function AppRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/orders">
          <Orders />
        </Route>
        <Route exact path="/metrics">
          <Metrics />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/categories">
          <Categories />
        </Route>
        <Route exact path="/categories/:category_id">
          <CategoryDetails />
        </Route>
        <Route exact path="/products/:product_id">
          <ProductDetails />
        </Route>
      </Switch>
    </Router>
  );
}
