import React, { useEffect, useState } from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Container } from './styles';
import MenuWrapper from '../../components/MenuWrapper';
import { WithMenuContainer } from '../../styles/global';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

const columns = [
  {
    field: 'title',
    headerName: 'Título',
    width: 200,
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 250,
  },
  {
    field: 'average',
    headerName: 'Ticket Médio',
    width: 150,
    type: 'number',
    valueGetter: (params: any) =>
      `${params.row.average.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },

  {
    field: 'sells',
    headerName: 'Vendas',
    type: 'number',
    width: 110,
  },
  {
    field: 'renue_montly',
    headerName: 'Receita de abril',
    type: 'number',
    width: 180,
    valueGetter: (params: any) =>
      `${params.row.renue_montly.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'renue_total',
    headerName: 'Receita total',
    type: 'number',
    width: 180,
    valueGetter: (params: any) =>
      `${params.row.renue_total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
  },
  {
    field: 'updated_at',
    headerName: 'Última alteração',
    type: 'number',
    width: 200,
    valueGetter: (params: any) =>
      `${new Date(params.row.updated_at).toLocaleString()}`,
  },
  {
    field: 'created_at',
    headerName: 'Data de criação',
    type: 'number',
    width: 200,
    valueGetter: (params: any) =>
      `${new Date(params.row.created_at).toLocaleString()}`,
  },
];

const Categories = () => {
  const [categoriesData, setCategoriesData] = useState([]);

  const [rows, setRows] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function load() {
      const { data } = await api.get(
        `/places/6265a43d21ca6e602ea5a41e/categories`
      );

      setCategoriesData(data);

      const newRows = data.map((category: any) => ({
        id: category._id,
        title: category.title,
        description: category.description,
        average: 0,
        sells: 0,
        renue_montly: 0,
        renue_total: 0,
        updated_at: category.updatedAt,
        created_at: category.createdAt,
      }));

      setRows(newRows);
    }

    load();
  }, []);

  return (
    <WithMenuContainer>
      <MenuWrapper />
      <Container>
        <div className="top-head">
          <h2>Categorias</h2>
          <Link to="/categories/new">
            <button className="cta-main">Adicionar</button>
          </Link>
        </div>
        <div className="table">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            style={{ fontFamily: 'Chillax' }}
            onRowClick={(e) => {
              history.push(`/categories/${e.id}`);
            }}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick
            editMode="row"
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
          />
        </div>
      </Container>
    </WithMenuContainer>
  );
};

export default Categories;
