import styled, { css } from 'styled-components';

import burgerImg from '../../assets/burger.png';

export const Container = styled.div`
  grid-area: content;

  /* padding: 50px; */
  background: #f5f5f5;
  display: flex;
  /* padding: 50px 50px 50px 0; */
  flex-direction: column;

  position: relative;

  .scroll-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .rcs-inner-container {
      padding: 50px 50px 50px 0;

      padding-left: 1px;
    }
    .rcs-inner-handle {
      background: #d2d2d2;
    }
  }

  .bottom-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .cta-secoundary {
      margin-right: 10px;
    }
  }

  h1 {
    color: #000;
    font-size: 11px;
  }

  .top-head {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      line-height: 100%;
      display: inline-flex;
      color: #181818;
    }
    h2 {
      margin-left: 15px;
    }
  }

  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }
  h3 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
  }

  .divider {
    width: 100%;
    background: #d6d6d6;
    height: 2px;
    margin: 50px 0;
    display: block;
  }

  form {
    margin-top: 50px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 30px;
    }

    .product-variants {
      width: 100%;
      margin-top: 30px;

      .product-variants-head {
        display: flex;
        align-items: flex-start;

        .titles {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .add-button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #ff0018;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
          margin-left: 30px;
        }

        label {
          font-family: 'Satoshi';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 100%;

          color: #181818;
          margin-bottom: 15px;
        }

        small {
          font-family: 'Satoshi';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 120%;
          color: #808080;
          /* margin-top: 15px; */
        }

        label {
        }
      }

      .product-variants-list {
        margin-top: 30px;
        width: 100%;

        .product-variant-item {
          width: 100%;

          display: grid;
          grid-template-columns: 0.75fr 1.3fr 1fr;
          grid-gap: 0 20px;
          /* .input-group {
            } */

          margin-bottom: 30px;

          .product-variant-group {
            width: 100%;
          }

          .btn-black {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #181818;
            border-radius: 8px;
            cursor: pointer;
            border: none;
            width: 100%;
            height: 58px;
            margin-top: 20px;
            font-family: 'Satoshi';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;

            text-align: center;

            color: #ffffff;
          }

          .option-type-group {
            label {
              font-family: 'Satoshi';
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 100%;

              color: #181818;
              margin-bottom: 15px;
            }

            small {
              margin-top: 0px !important;
            }

            .selection-types {
              margin-top: 30px;

              .selection-type.selected {
                strong {
                  font-weight: 600;
                  color: #ff0018;
                }
                .radio {
                  background: #eb717c;
                  border: 3px solid #ff0018;
                }
              }

              .selection-type {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                cursor: pointer;
                .radio {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: #eeeeee;
                  border: 3px solid #d2d2d2;
                  margin-right: 10px;
                }

                strong {
                  font-family: 'Chillax';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 100%;
                  color: #969696;
                }
              }
            }
          }

          .options-wrap {
            /* height: 200px; */
            label {
              font-family: 'Satoshi';
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 100%;

              color: #181818;
              margin-bottom: 15px;
            }

            small.wrap-small {
              display: block;
              font-family: 'Satoshi';
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 120%;
              color: #808080;
              margin-top: 15px;
            }

            .variants-grid {
            }

            .wrap {
              height: 200px;
              overflow: scroll;
              min-height: 58px;
              width: 100%;
              background: #f1f1f1;
              padding: 20px;
              margin-top: 20px;
              display: grid;
              grid-gap: 10px;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
      }
    }
  }

  .input-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }

  .row-prices {
    justify-content: space-between;
    .input-group {
      width: calc(50% - 7.5px);
    }
  }
  .row-categories {
    justify-content: space-between;
    .input-select-group {
      width: calc(50% - 7.5px);
    }
  }

  .input-select-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 20px;
    label {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 100%;

      color: #181818;
      margin-bottom: 20px;
    }

    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #808080;
      margin-top: 15px;
    }

    .input-select-wrap {
      width: 100%;

      .input-select__control {
        height: 58px;
        padding: 5px 20px !important;
      }

      .input-select__value-container {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #181818;
      }

      .input-select__option {
        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
      }
    }
  }

  .product-type-group {
    width: 100%;
    .options {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .type-option {
        cursor: pointer;
        width: 50%;
        height: 58px;
        background: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'Chillax';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-align: center;

        color: #969696;
      }

      .type-option.selected {
        background: #fbd9dc;
        border: 1px solid #ff0018;
        color: #ff0018;
        font-weight: 600;
      }
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    label {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 100%;

      color: #181818;
      margin-bottom: 20px;
    }

    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #808080;
      margin-top: 15px;
    }

    input {
      min-height: 58px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 0 20px;
      color: #181818;
    }
    input::placeholder {
      color: #969696;
    }

    textarea {
      min-height: 100px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      resize: vertical;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 20px;
      color: #181818;
    }

    textarea::placeholder {
      color: #969696;
    }
  }
`;

export const ModalVariantsContainer = styled.div`
  h2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 208.5%; */
    /* or 42px */

    color: #181818;
    margin-bottom: 20px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    label {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 100%;

      color: #181818;
      margin-bottom: 20px;
    }

    small {
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #808080;
      margin-top: 15px;
    }

    input {
      min-height: 58px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 0 20px;
      color: #181818;
    }
    input::placeholder {
      color: #969696;
    }

    textarea {
      min-height: 100px;
      width: 100%;
      background: #f1f1f1;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      resize: vertical;
      font-size: 14px;
      line-height: 100%;
      /* or 17px */
      border: none;
      padding: 20px;
      color: #181818;
    }

    textarea::placeholder {
      color: #969696;
    }
  }
`;

export const ProductVariantOptionContainer = styled.div`
  /* max-width: 155px; */
  width: 100%;
  /* min-height: 42px; */
  background: #ffffff;
  box-shadow: 0px 0px 27px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  /* padding: 20px 20px 20px 0px; */
  min-height: 42px;
  height: max-content;

  .modal-variants {
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 20px;

    min-height: 42px;
    /* height: fit-content; */
  }

  .option-price {
    display: flex;
    flex-direction: row;
    padding: 5px;
    background: #ff0018;
    width: 100%;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: #fff;
  }

  .action {
    cursor: pointer;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d3d3d3;
    font-size: 14px;
    color: #181818;
    margin-right: 20px;
  }

  strong {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #181818;
  }
`;
